import './filters.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, DatePicker, Row, Select,Input } from 'antd'
import moment from 'moment'

const { Option } = Select

const PurchaseHistoryFilters =({
	originPage = 'user',
	onSetFilters,
	onRestoreFilters
}) => {

	const { t } = useTranslation()
	const [datePickerIsDisabled, setDatePickerIsDisabled] = useState(true)
	const [keyword,setKeyword] = useState('')
	const [filtersDate,setFiltersDate] = useState('ALL')
	const [filtersDateFrom,setFiltersDateFrom] = useState('')
	const [filtersDateTo,setFiltersDateTo] = useState('')
	const [productId, setProductId] = useState(null);
	const [nroTransaccion, setNroTransaccion] = useState('');
	const [rutUsuario, setRutUsuario] = useState('');

	const handleDateChange = (value) => {
		if (value === 'RANGE') {
			setDatePickerIsDisabled(false)
		} else {
			setDatePickerIsDisabled(true)
		}

		handleOnChangeDate(value)
	}

	const initialFiltersState = {
		keyword,
		filtersDate,
		filtersDateFrom,
		filtersDateTo,
		productId,
		nroTransaccion,
		rutUsuario
	}

	const defaultValues={ keyword, filtersDate, filtersDateFrom, filtersDateTo, productId, nroTransaccion}

	const setKeywordHandler = ({target}) => {
		setKeyword(target.value)
	}
	const handleOnChangeDate = (filtersDate) => {
		setFiltersDate(filtersDate)
	}
	const handleOnChangeDateFrom = (filtersDateFrom) => {
		setFiltersDateFrom(moment(filtersDateFrom).format('DD/MM/YYYY'))
	}
	const handleOnChangeDateTo = (filtersDateTo) => {
		setFiltersDateTo(moment(filtersDateTo).format('DD/MM/YYYY'))
	}

	const handleOnClickRestoreFilters = () => {
		setProductId(null)
		setKeyword(null)
		setFiltersDate('ALL')
		setNroTransaccion(null)
		setRutUsuario(null)
		onRestoreFilters()
	}

	const getFilters = () => {
		const filters = {}
		for (let key in initialFiltersState ) {
			let value = initialFiltersState[key]
			if (key === 'filtersDate') {
				filters['optDates'] = value
				if (value === 'RANGE') {
					filters['fromDate'] = filtersDateFrom
					filters['toDate'] = filtersDateTo
				}
			}
			if (originPage === 'user' && key=='keyword') {
				filters['keyword'] = value
			}
			if(originPage === 'admin' && key === 'productId'){
				filters['productId'] = value
			}
			if(originPage != 'user' && key === 'nroTransaccion'){
				filters['nroTransaccion'] = value
			}
			if(originPage != 'user' && key === 'rutUsuario'){
				filters['rutUsuario'] = value
			}
		}
		return filters
	}

	const buildOnSubmit = () => {
		onSetFilters(getFilters())
	}

	return (
		<div className='admin-search-filters'>
			<Row>
				{originPage === 'user' ?
					<Col span={4}>
						<div className="input module">
							{ t('messages.aml.keyword') } :
							<div className="module-inner">
								<Input value = {keyword} onChange = {setKeywordHandler}/>
							</div>
						</div>
					</Col>
				:
					<>
						{originPage === 'admin' &&
							<Col span={4}>
								<div className="input module">
									Producto
									<div className="module-inner">
										<Select style={{width: '100%'}} value={productId} onChange = {(value) => {setProductId(value)}}>
											<Option value={null}>Todos</Option>
											<Option value="1"> Malla de propiedad </Option>
											<Option value="2">Participación en sociedades</Option>
											<Option value="3">Reporte Compliance (PEP)</Option>
										</Select>
									</div>
								</div>
							</Col>
						}
						<Col span={3}>
							<div className="input module">
								Nro. Transacción
								<div className="module-inner">
									<Input value = {nroTransaccion} onChange = {(e) => {setNroTransaccion(e.target.value)}}/>
								</div>
							</div>
						</Col>
					</>
				}
				<Col span={originPage == 'user' ? 5 : 4}>
					<div className="dates module">
						{ t('messages.aml.dates') } :
						<div className="module-inner">
							<Select defaultValue={ defaultValues.filtersDate } value={ defaultValues.filtersDate } onChange={ handleDateChange } style={{ width: '100%' }}>
								<Option value="ALL">[ { t('messages.aml.allDates') } ]</Option>
								<Option value="TODAY">{ t('messages.aml.today') }</Option>
								<Option value="WEEK">{ t('messages.aml.lastWeek') }</Option>
								<Option value="MONTH">{ t('messages.aml.lastMonth') }</Option>
								<Option value="3MONTH">{ t('messages.aml.last3Months') }</Option>
								<Option value="RANGE">{ t('messages.aml.customRange')}</Option>
							</Select>
						</div>
					</div>
				</Col>
				<Col span={originPage == 'user' ? 8 : 6}>
					<div className="date-pickers module">
						<Row>
							<Col xs={ 12 }>
								{ t('messages.aml.fromDate') } :
								<div className="module-inner">
									<DatePicker format="DD/MM/YYYY" defaultValue={ defaultValues.filtersDateFrom } disabled={ datePickerIsDisabled } onChange={ handleOnChangeDateFrom } placeholder="Fecha desde" style={{ opacity: datePickerIsDisabled ? 0.5 : 1 }} />
								</div>
							</Col>
							<Col xs={ 12 }>
								{ t('messages.aml.toDate') } :
								<div className="module-inner">
									<DatePicker format="DD/MM/YYYY" defaultValue={ defaultValues.filtersDateTo } disabled={ datePickerIsDisabled } onChange={ handleOnChangeDateTo } placeholder="Fecha hasta" style={{ opacity: datePickerIsDisabled ? 0.5 : 1 }} />
								</div>
							</Col>
						</Row>
					</div>
				</Col>
				{originPage !== 'user' &&
					<Col span={3}>
						<div className="input module">
							Rut usuario
							<div className="module-inner">
								<Input value = {rutUsuario} onChange = {(e) => {setRutUsuario(e.target.value)}}/>
							</div>
						</div>
					</Col>
				}
				<Col span={originPage === 'admin' ? 2 : 3} offset={originPage === 'admin' ? 0 : originPage === 'admin-pagos' ? 2 : 1}>
					<div className="submit module">
						<div className="module-inner">
							<Button type="primary" onClick={ buildOnSubmit } icon="check">{ t('messages.aml.apply') }</Button>
						</div>
					</div>
				</Col>
				<Col span={originPage === 'admin' ? 2 : 3}>
					<div className="restore module">
						<div className="module-inner">
							<Button onClick={ handleOnClickRestoreFilters } icon="undo" ghost>{ t('messages.aml.restore') }</Button>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default PurchaseHistoryFilters