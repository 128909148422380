import { ActivateRegisterClientService } from '../services'

export default (token) => {
	
	return new Promise(async (resolve, reject) => {
		await ActivateRegisterClientService.save(token)
			.then(response => resolve(response.data))
			.catch(err => reject({ error: true }))
	})
}
