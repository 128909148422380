import React from "react"
import { Col, Row} from 'antd'
import './leftVideo.scss'

const LeftVideo = () => {
	return (
		<div className="leftVideo">
			<iframe width="558" height="100%" src="https://www.youtube.com/embed/qfEZR6GH1U8?rel=0&modestbranding=1&controls=0&showinfo=0&disablekb=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
		</div>
	)
}

export default LeftVideo
