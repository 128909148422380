import './wallet.scss'
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Button, Input, Row } from 'antd'
import { payPromise } from './promises'
import { userContext } from '../../../../../../contexts'
import apiConfig from '../../../../../../config/api'
import amexImg from '../../../../../Landing/components/Payment/amex.png'
import dinersImg from '../../../../../Landing/components/Payment/diners.png'
import khipuImg from '../../../../../Landing/components/Payment/khipu.png'
import maestroImg from '../../../../../Landing/components/Payment/maestro.png'
import mastercardImg from '../../../../../Landing/components/Payment/mastercard.png'
import multicajaImg from '../../../../../Landing/components/Payment/multicaja.png'
import redcompraImg from '../../../../../Landing/components/Payment/redcompra.png'
import transbankImg from '../../../../../Landing/components/Payment/transbank.png'
import visaImg from '../../../../../Landing/components/Payment/visa.png'
import moneyBagImg from './money-bag.png'
import { PaymentHistory } from '../'

const Wallet = ({ currentAmount }) => {
	const { user } = useContext(userContext)

	const [ isButtonClicked, setIsButtonClicked ] = useState(false)

	const [ xAccountId, setXAccountId ] = useState('')
	const [ xReference, setXReference ] = useState('')
	const [ xAmount, setXAmount ] = useState('')
	const [ xCurrency, setXCurrency ] = useState('')
	const [ xCustomerEmail, setXCustomerEmail ] = useState('')
	const [ xUrlComplete, setXUrlComplete ] = useState('')
	const [ xUrlCancel, setXUrlCancel ] = useState('')
	const [ xUrlCallback, setXUrlCallback ] = useState('')
	const [ xShopCountry, setXShopCountry ] = useState('')
	const [ xSessionId, setXSessionId ] = useState('')
	const [ xSignature, setXSignature ] = useState('')

	const handleAddMoney = async () => {
		setIsButtonClicked(true)

		const amount = parseInt(document.getElementById('add-money-amount').value)
		
		payPromise(amount).then((response) => {
            window.location.replace(response.url+"?token="+response.token);
        })
		/*
		const { accountId, orderNro, sessionId, signature } = uniques
		const payload = {}

		setXAccountId(accountId)
		payload['x_account_id'] = xAccountId

		setXReference(orderNro)
		payload['x_reference'] = orderNro

		setXAmount(amount)
		payload['x_amount'] = amount

		setXCurrency('CLP')
		payload['x_currency'] = xCurrency

		setXCustomerEmail(user.email)
		payload['x_customer_email'] = xCustomerEmail

		setXUrlComplete(apiConfig.url + '/payment/success')
		payload['x_url_complete'] = xUrlComplete

		setXUrlCancel(apiConfig.url + '/payment/failure')
		payload['x_url_cancel'] = xUrlCancel

		setXUrlCallback(apiConfig.url + '/payment/complete')
		payload['x_url_callback'] = xUrlCallback

		setXShopCountry('CL')
		payload['x_shop_country'] = 'CL'

		setXSessionId(sessionId)
		payload['x_session_id'] = sessionId

		setXSignature(signature)
		payload['x_signature'] = signature

		const form = document.getElementById('add-money-form')

		form.submit()
		*/
	}

	return (
		<div className="wallet">
			<form id="add-money-form" method="post" action={ apiConfig.paymentUrl }>
				<input name="x_account_id" type="hidden" value={ xAccountId } />
				<input name="x_reference" type="hidden" value={ xReference } />
				<input name="x_amount" type="hidden" value={ xAmount } />
				<input name="x_currency" type="hidden" value={ xCurrency } />
				<input name="x_customer_email" type="hidden" value={ xCustomerEmail } />
				<input name="x_url_complete" type="hidden" value={ xUrlComplete } />
				<input name="x_url_cancel" type="hidden" value={ xUrlCancel } />
				<input name="x_url_callback" type="hidden" value={ xUrlCallback } />
				<input name="x_shop_country" type="hidden" value={ xShopCountry } />
				<input name="x_session_id" type="hidden" value={ xSessionId } />
				<input name="x_signature" type="hidden" value={ xSignature } />

				<div className="top-block">
					<div className="balance-block">
						<Row>
							<Col className="amount-col" span={ 10 }>
								<img src={ moneyBagImg } alt="" />
								<small>Tu saldo actual</small>
								<h1>$ { currentAmount }</h1>
							</Col>
							<Col className="add-funds" span={ 14 }>
								<Input id="add-money-amount" placeholder="Ejemplo: 35000" />
								<Button icon="plus" type="primary" onClick={ handleAddMoney } disabled={ isButtonClicked ? true : false }>Agregar fondos</Button>
							</Col>
						</Row>
						<div className="payment-methods">
							<div><img src={ redcompraImg } alt="" /></div>
							<div><img src={ visaImg } alt="" /></div>
							<div><img src={ mastercardImg } alt="" /></div>
							<div><img src={ transbankImg } alt="" /></div>
							<div><img src={ maestroImg } alt="" /></div>
							<div><img src={ dinersImg } alt="" /></div>
							<div><img src={ multicajaImg } alt="" /></div>
							<div><img src={ khipuImg } alt="" /></div>
							<div><img src={ amexImg } alt="" /></div>
						</div>
					</div>
				</div>
			</form>
			<div className="payment-history-component">
				<h3>Últimos movimientos</h3>
				<PaymentHistory />
			</div>
		</div>
	)
}

Wallet.propTypes = {
	currentAmount: PropTypes.number.isRequired
}

export default Wallet
