import './footer.scss'
import React, { useState } from 'react'
import { Button, Col, Icon, Modal, Row } from 'antd'
import { Logo } from '../'
import addressIcon from './address.png'
import phoneIcon from './phone.png'

const Footer = () => {
	const [ isContentVisible, setIsContentVisible ] = useState(false)
	const [ url, setUrl ] = useState(null)
	const [ content, setContent ] = useState(null)
	const [ contentType, setContentType ] = useState(null)

	const handleModalOpen = (type, content) => {
		setIsContentVisible(true)

		if (type === 'external') {
			setContentType('external')
		}
 
		if (type === 'native') {
			setContentType('native')
		}

		setContent(content)
	}

	const handleModalClose = () => {
		setIsContentVisible(false)
	}

	const informationPolitics = (
		<>
			<h1>Seguridad de la Información</h1>
			<p>La información, en todas sus formas, es un bien valioso y esencial para Gesintel y sus sistemas de información son activos críticos.</p>
			<p>La Seguridad de la Información es un elemento esencial en el diseño, construcción y operación de los productos, redes y servicios a clientes ofrecidos por Gesintel.</p>
			<p>Gesintel protegerá la información que maneja en forma adecuada a su valor y criticidad, independientemente de los medios que la contengan, y velando especialmente por su disponibilidad, confidencialidad e integridad.</p>
			<p>Gesintel cumplirá con las normativas vigentes y requerimientos legales relacionados con la seguridad de la información en los países donde opera.</p>
			<p>Gesintel definirá la normativa interna con medidas de protección de los activos y recursos de información, así como medidas de mitigación de incidentes y planes de recuperación, para garantizar la seguridad de la información y la continuidad del negocio.</p>
			<p>La empresa reconoce que la sensibilización, capacitación y entrenamiento adecuados a su personal en las materias de Seguridad de la Información, son tareas prioritarias.</p>
			<p>Esta política, así como los reglamentos y procedimientos relacionados con la seguridad de información, son obligatorios para todo el personal.</p>
			<p>Todos los trabajadores, y muy especialmente las jefaturas y ejecutivos, son personalmente responsables por la custodia y protección de la información que manejan en el desempeño de sus funciones.</p>
			<p>El Oficial y el Comité de Seguridad de Gesintel tienen la responsabilidad por la generación, difusión y actualización de la Política de Seguridad de la Información, las políticas específicas y las normativas y procedimientos asociados, así como por la verificación de su cumplimiento.</p>
		</>
	)

	return (
		<Row>
			<Col xs={24} lg={24}>
				<div className="footer">
					<div className="footer-inner">
						<Row gutter={[0, 40]}>
							<Col xs={24} md={12}>
								<Col>
									<Logo />
								</Col>
								<div className="company-info">
								<h3>EXPERTOS EN COMPLIANCE, RIESGO, TECNOLOGÍA Y MANEJO DE DATOS</h3>
									<div className='footer-rrss'>
										<a href='https://twitter.com/GesintelChile' target='_blank'><Icon type="twitter" /></a>
										<a href='https://cl.linkedin.com/company/gesintelaml' target='_blank'><Icon type="linkedin" /></a>
										<a href='https://www.youtube.com/channel/UC8JpCsyE6Sx2qNGzmD-7CtQ' target='_blank'><Icon type="youtube" /></a>
									</div>
									<div>
										<a className='security-policy' href="https://gesintel.cl/2023/09/politica-de-seguridad-tienda/" target='_blank'>POLITICAS DE SEGURIDAD</a>
									</div>
								</div>
							</Col>
							<Col xs={24} md={12}>
								<div className="navigation">
									<Col span={12}>
										<div className="know-us">
											<h3>Contáctanos</h3>
											<Row gutter={[0, 10]}>
												<Col xs={12} xl={24}>
													<a href="mailto:contacto@gesintel.cl">
														<Icon type="mail" /> contacto@gesintel.cl
													</a>
												</Col>
												<Col xs={12} xl={24}>
													<a href="tel:+56232445100">
														<Icon type="phone" /> +56 2 32445100
													</a>
												</Col>
												<Col xs={12} xl={24}>
													<a href="https://www.gesintel.cl">
														<Icon type="global" /> www.gesintel.cl
													</a>
												</Col>
											</Row>
										</div>
									</Col>
									<Col xs={12}>
										<div className="sucursal">
											<h3>Sucursal</h3>
											<div>
												<a href='https://goo.gl/maps/qnxrDqnqLoeVSUSR9'>
													<div>
														<Icon type="environment" /> Av. Alcalde Fernando Castillo Velasco 6864, La Reina, Región Metropolitana.
													</div>
												</a>
											</div>
										</div>
									</Col>
								</div>
							</Col>
						</Row>
					</div>
					<div className="copy">
						Copyright &copy; 2023 Gesintel Compliance S.A. - Todos los derechos reservados.
					</div>
					<Modal
						className={ contentType === 'native' ? 'footer-content is-native' : 'footer-content is-external' }
						footer={ null }
						header={ null }
						onCancel={ handleModalClose }
						visible={ isContentVisible }
					>
						<>
							{ contentType === 'external' &&
								<div className="iframe-wrapper">
									<iframe src={ content }></iframe>
								</div>
							}
							{ contentType === 'native' &&
								<>
									<div className="content-wrapper">
										{ content }
									</div>
									<div className="understood-button">
										<Button type="primary" onClick={ handleModalClose }>Entendido</Button>
									</div>
								</>
							}
						</>
					</Modal>
				</div>
			</Col>
		</Row>
	)
}

export default Footer
