import React, {useState,useContext} from 'react'
import {Modal, Button, Spin} from 'antd'
import { activeTabIndexContext,userContext } from '../../../../contexts'
import {numberFormatterHelper} from '../../../../helpers'
import './confirmModal.scss'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

const ConfirmModal = ({items,product,confirmPurchase,cancelConfirmPurchase,isConfirmModalVisible}) => {

	const {productsPrices,user} = useContext(userContext)
	const [isLoading, setIsLoading] = useState(false)
	const {t} = useTranslation()
	const { tabIndexHandler } = useContext(activeTabIndexContext)


	const handleConfirmPurchaseInner = () => {
		setIsLoading(true)
		confirmPurchase().then( async (response)=>{
			setIsLoading(false)
		})
	}

	const handleOnRecharge = ()=>{
		tabIndexHandler(4)
	}


	return(
		<div className = "confirmation-modal">
			<Modal
				className="purchase-confirmation-modal"
				footer={ null }
				onCancel={ ()=>cancelConfirmPurchase() }
				visible={ isConfirmModalVisible }
			>
				<div className="purchase-confirmation-modal-inner">
					<div className="purchase-confirmation-header">
						<h1>Confirmación de Solicitud</h1>
					</div>
					{
						product.price * items.length > user.saldo ?
							<div className="wallet-notification no-money"> {t('messages.aml.youDontHaveEnoughBalance')} <strong>$ { numberFormatterHelper(user.saldo) }</strong> <Button onClick = {handleOnRecharge} size="small" type="primary">Recargar saldo</Button></div>
							:
							<div className="wallet-notification"> {t('messages.aml.buying',{balance:numberFormatterHelper(user.saldo), pressButton:'Confirmar Solicitud',subTotal: numberFormatterHelper(product.price * items.length)})} </div>
					}
					<div className="request-ruts">
						<table cellPadding="0" cellSpacing="0">
							<thead>
								<th style={{ textAlign: 'center' }}>#</th>
								<th>Item / Producto</th>
								<th style={{ textAlign: 'right', width: 50 }}>Precio</th>
							</thead>
							{
								true &&
            items.map((item,i)=>{
            	return(
            		<tr key = {i}>
            			<td style={{ textAlign: 'center' }}>
            				{i+1}
            			</td>
            			<td>
            				{`${product.name} \u2192 Rut: ${item.rut}`}
            			</td>
            			<td td style={{ textAlign: 'right', width: 100 }}>
            				{numberFormatterHelper(product.price)}
            			</td>
            		</tr>
            	)
            })
							}
						</table>
						<div className="wallet-price">
							<div className="price-label">
								<span>Precio total :</span>
							</div>
							<div className="price">
								{
									true &&
              <span>$ {numberFormatterHelper(items.length*product.price) }</span>
								}
							</div>
						</div>
					</div>
					<Button className="confirm-button" icon = {isLoading ? "loading" : "check"} disabled = {product.price * items.length > user.saldo ? true : false} type="primary" onClick={ handleConfirmPurchaseInner } > <strong>Confirmar Solicitud</strong></Button>
					<Button className="cancel-button" icon="close" type="primary" onClick={cancelConfirmPurchase}>Cerrar</Button>
				</div>
			</Modal>
		</div>
	)
}

export default withRouter(ConfirmModal)
