import HomeTitleComponent from './HomeTitle/HomeTitle'
import MainDivComponent from './MainDiv/MainDiv'
import PaymentComponent from './Payment/Payment'
import ProductsComponent from './Products/Products'
import TestimonialsComponent from './Testimonials/Testimonials'
import ServicesComponent from './Services/Services'

export const HomeTitle = HomeTitleComponent
export const MainDiv = MainDivComponent
export const Payment = PaymentComponent
export const Products = ProductsComponent
export const Testimonials = TestimonialsComponent
export const Services = ServicesComponent
