import { newRequestService } from '../services'

export default (productId, request) => {
	return new Promise((resolve, reject) => {

		newRequestService(productId, request)
			.then((response) => {
				resolve({ 
					data: response.data,
					status: response.status
				
				})
			})
			.catch(() => {
				reject({ error: 'error generico' })
			})
	})
}
