
import './products.scss'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Icon, Row, Tooltip } from 'antd'
import { registerFormHighlighterHelper, scrollToTopHelper } from '../../../../helpers'
import meshImage from './mesh.png'
import societyImage from './society.png'
import shareHoldersImage from './shareholders.png'
import filesImg from './files.png'
import { Link } from 'react-router-dom'
import { userContext } from '../../../../contexts'


const Products = () => {

	const infoProd = useContext(userContext).productsPrices.data;

	useEffect(() => {
		console.log(infoProd)
	}, []);

	return (
		<>
			<div className="home-products">
				<div className="image-wrapper" alt="">
					<img src={ filesImg } alt="" />
				</div>

				<Row>
					<Col span={24}>
						<div style={{textAlign: 'center', margin: '0px 0px 0px 0px'}}>
							<big className="products-block-title">Obtén tu reporte en línea.</big>
						</div>
					</Col>
					<Col span={24}>
						<h3>Datos rápidos y actualizados.</h3>
					</Col>
				</Row>
					
				<Row gutter={[20, 20]} type='flex' className='home-products-wrapper'>
					<Col xs={24} lg={8}>
						<div className='home-product'>
							<div className="home-product-header">
								<img src={ meshImage } alt="" />
							</div>
							<div className="home-product-title">
								Malla de Propiedad
							</div>
							<div className="home-product-description">
								Informe actualizado de los beneficiarios finales de una persona jurídica.
							</div>
							<div className="home-product-features">
								<ul>
									<li><Icon type="check" /> Malla de Propiedad con todos los niveles incluyendo el RUT, Nombre y Participación de cada beneficiario final.</li>
									<li><Icon type="check" /> Identificación de empresas dueñas de otras empresas y sus participaciones.</li>
									<li><Icon type="check" /> Información verificada de fuentes públicas.</li>
									<li><Icon type="check" /> Monitoreo de riesgo solo a los Beneficiarios Finales (Mayor al 10% de participación).</li>
								</ul>
							</div>
							<div className="home-product-price">
								Precio por RUT <br/><strong>${infoProd[0].price}</strong>
							</div>
							<div className="home-product-action">
								<Link to='/buypage/1'>
									<Button type="primary">COMPRAR</Button>
								</Link>
							</div>
						</div>
					</Col>
					<Col xs={24} lg={8}>
						<div className='home-product'>
							<div className="home-product-header">
								<img src={ societyImage } alt="" />
							</div>
							<div className="home-product-title">
								Participación en Sociedades
							</div>
							<div className="home-product-description">
								Informe actualizado de las empresas en que participa una persona natural o jurídica.
							</div>
							<div className="home-product-features">
								<ul>
									<li><Icon type="check" /> Participación en sociedades de una persona, identificando la Razón Social, RUT y % de participación.</li>
									<li><Icon type="check" /> Identificación de la totalidad de empresas en las que participa la persona.</li>
									<li><Icon type="check" /> Información verificada de fuentes públicas.</li>
								</ul>
							</div>
							<div className="home-product-price">
								Precio por RUT <br/><strong>${infoProd[1].price}</strong>
							</div>
							<div className="home-product-action">
							<Link to='/buypage/2'>
								<Button type="primary">COMPRAR</Button>
							</Link>
							</div>
						</div>
					</Col>
					<Col xs={24} lg={8}>
						<div className='home-product'>
							<div className="home-product-header">
								<img src={ shareHoldersImage } alt="" />
							</div>
							<div className="home-product-title">
								Persona Expuesta Políticamente
							</div>
							<div className="home-product-description">
								Verifica si tu cliente es una Persona Políticamente Expuesta o está en una lista de riesgo.
							</div>
							<div className="home-product-features">
								<ul>
									<li><Icon type="check" /> Podrá saber si la persona consultada es <Tooltip title = "Personas Expuestas Políticamente">PEP</Tooltip> o está relacionada con un <Tooltip title = "Personas Expuestas Políticamente">PEP</Tooltip></li>
									<li><Icon type="check" /> Podrá saber si la persona consultada es Funcionario Público de alguna repartición del estado.</li>
									<li><Icon type="check" /> Búsqueda de personas de interés.</li>
									<li><Icon type="check" /> Búsqueda en listas internacionales</li>
								</ul>
							</div>
							<div className="home-product-price">
								Precio por RUT <br/><strong>${infoProd[2].price}</strong>
							</div>
							<div className="home-product-action">
								<Link to='/buypage/3'>
									<Button type="primary">COMPRAR</Button>
								</Link>
							</div>
						</div>
					</Col>
				</Row>
				
				<div className="clearer" />
			</div>
		</>
	)
}

export default Products
