import './topbar.scss'
import React, { useContext } from 'react'
import { Button, Icon,Tooltip } from 'antd'
import { userContext } from '../../../../contexts'
import PropTypes from 'prop-types'
import {NewRequest} from './components'

const TopBar = ({ setActiveTabIndex }) => {
	const { isLoggedIn, user } = useContext(userContext)


	return (
		<div className="top-bar">
			<Icon className="smiley-icon" type="smile" />
			{ isLoggedIn && <h1>Buenos días, { user.name.split(' ')[0] } !</h1> }
			{ isLoggedIn &&
				<div className="wallet">
					<span>Tu monedero : &nbsp;&nbsp;</span>
					<div className="wallet-inner">
						<strong>$ { user.saldo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</strong>
						<Button icon="plus" size="small" type="primary" onClick={() => setActiveTabIndex(4)}>Cargar más</Button>
					</div>
				</div>
			}
			{ isLoggedIn &&
		<div className = "tooltip1">
			<Button
				onClick={ () => setActiveTabIndex(0) }
				type="primary" icon="plus"
			>
				Nueva Solicitud
			</Button>
		</div>


			}


		</div>
	)
}


export default TopBar
