import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers/'

const returnPay = {
    getTransactionDetail: (transactionId) => {
        return apiRequestorHelper({
            url: apiConfig.url + '/guest/getTransactionDetail/'+transactionId,
            method: 'post',
        })
    },
    getPaymentDetail: (paymentId) => {
        return apiRequestorHelper({
            url: apiConfig.url + '/guest/getPaymentDetail/'+paymentId,
            method: 'post',
        })
    },
}
export default returnPay;
