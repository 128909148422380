import './header.scss'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { Button } from 'antd'
import { userContext } from '../../../../contexts'
import { Logo } from '../'

const Header = ({ history }) => {
	const { user, isLoggedIn, logoutHandler, handlerTabActive } = useContext(userContext)

	const handleLogin = async () => {
		history.push('/login')
	}

	const handleLogout = async () => {
		await logoutHandler()

		history.push('/')
	}


	const handleClickOnUser = ()=>{
		handlerTabActive(3)
	}

	

	return (
		<>
		<Link to='/'>
			<Logo />
		</Link>
			<div className={ "header" + (isLoggedIn ? " is-logged-in" : " is-logged-out") }>
				<div className="header-inner">
					<div className="private">
						<Button icon="logout" type="primary" onClick={ handleLogout }>Cerrar sesión</Button>
						<Button onClick={handleClickOnUser} className="username" icon="user" type="primary">{ user.name }</Button>
					</div>
					<div className="public">
						<Button icon="login" type="primary" onClick={ handleLogin }>Acceder</Button>
					</div>
				</div>
			</div>
		</>
	)
}

Header.propTypes = {
	history: PropTypes.object.isRequired
}

export default withRouter(Header)
