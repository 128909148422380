import { initPaymentService } from '../services'

export default (amount) => {
	return new Promise(resolve => {
		initPaymentService(amount)
			.then(response => {
				resolve(response.data)
			})
	})
}
