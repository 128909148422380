
import { getAdminDataService } from '../services'

const getPurchaseHistory = (from, size, filters) => {
    return new Promise(async (resolve, reject) => {
    await getAdminDataService.getPurchaseHistory(from, size, filters)
        .then(response => resolve(response.data))
        .catch(err => err )
        // reject({ error: true }))
    })
}

export default getPurchaseHistory;