import './tabs.scss'
import React from 'react'
import PropTypes from 'prop-types'

const Tabs = ({ activeTabIndex, clickedTabHandler }) => {
	return (
		<div className="tabs">
			<ul>
				<li onClick={ e => clickedTabHandler(e, 0) } className={ activeTabIndex === 0 ? 'active' : '' }>Home</li>
				<li onClick={ e => clickedTabHandler(e, 1) } className={ activeTabIndex === 1 ? 'active' : '' }>Historial de pedidos</li>
				<li onClick={ e => clickedTabHandler(e, 3) } className={ activeTabIndex === 3 ? 'active' : '' }>Información personal</li>
				<li onClick={ e => clickedTabHandler(e, 4) } className={ activeTabIndex === 4 ? 'active' : '' }>Tu monedero</li>
			</ul>
		</div>
	)
}

Tabs.propTypes = {
	activeTabIndex: PropTypes.number.isRequired,
	clickedTabHandler: PropTypes.func.isRequired
}

export default Tabs
