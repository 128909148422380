import React from 'react'
import PropTypes from 'prop-types'
import { Content, Footer, Header, Wrapper } from './components'

const Default = ({ children, id }) => (
	<Wrapper id={ id }>
		<Header />
		<Content>
			{ children }
		</Content>
		<Footer />
	</Wrapper>
)

Default.propTypes = {
	children: PropTypes.object.isRequired,
	id: PropTypes.string.isRequired
}

export default Default
