import './purchaseItem.scss'
import React, {useState,useContext, Fragment,useEffect} from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Tooltip,Modal, Table, Spin, Button, Checkbox,Icon, Descriptions, Tabs, Card,notification } from 'antd'
import ownersImg from './owners.png'
import networkImg from './network.png'
import moment from 'moment'
import {withTranslation} from 'react-i18next'
import apiConfig from '../../../../../../../../config/api'
import {camelizerHelper,numberFormatterHelper} from '../../../../../../../../helpers'
import {getDetalleSolicitudClientPromise,purchaseDetailsClientPromise} from './promises'
import { userContext } from '../../../../../../../../contexts'
import { ConfirmModal } from '../../../../../'

const { TabPane } = Tabs


const PurchaseHistoryItem = ({ type,data,t,setActiveTabIndex}) => {
	const { productsPrices, refreshUserHandler, user } = useContext(userContext)
	const [product,setProduct] = useState({price: 0})
	const [isModalVisible,setIsModalVisible] = useState(false)
	const [isConfirmModalVisible,setIsConfirmModalVisible] = useState(false)
	const [isLoading,setIsLoading] = useState(false)
	const [detail, setDetail] = useState({})
	const [records, setRecords] = useState([])
	const [selectedUbos, setSelectedUbos] = useState([])
	const [subTotal, setSubTotal] = useState(0)
	const [noSaldo, setNoSaldo] = useState(false)
	const [showUbosTable, setShowUbosTable] = useState(false)

	useEffect(()=>{
		setProduct(productsPrices.data.filter((product)=>(product.id === '3'))[0])
	},[])

	const handleCancel = () => {
		setIsModalVisible(false)
		setSubTotal(0)
		setSelectedUbos([])
	}

	const handleOk = () => {
		setIsConfirmModalVisible(true)
	}



	const handleOnChangeCheckPep = (record) => {
		return ({target})=>{
			if (target.checked == true){
				records.filter(e=> e.id == record.id)[0].checked = true
			} else {
				records.filter(e=> e.id == record.id)[0].checked = false
			}
			let ubosChecked = records.filter(e => e.checked)
			setSelectedUbos(ubosChecked)
			setSubTotal(numberFormatterHelper(ubosChecked.length*product.price))
			if ((ubosChecked.length*product.price) > user.saldo) {
				setNoSaldo(true)
			}
		}
	}


	const columns = [
		{
		  title: 'Rut',
			dataIndex: 'rut'
		},
		{
		  title: 'Nombre',
			dataIndex: 'name',
			render: (text) => {
				return camelizerHelper(text)
			}
		},
		{
		  title: 'Relación',
			dataIndex: 'relation',
			render: (text) => {
				return camelizerHelper(text)
			}
		},
		{
		  title: 'Participación',
			dataIndex: 'participacionDirecta',
			render: (text,record) => {
				if(text != null) {
					return text + '%'
				}
				return (
					<Tooltip title = "GESINTEL efectuó un esfuerzo razonable para encontrar esta información, sin embargo el porcentaje de participación no se encuentra disponible en la información pública">
						<Icon type = "info-circle"></Icon>
					</Tooltip>
						:
						""
				)
			}
		},
		{
		  title: 'Listas Compliance',
			dataIndex: 'baseAml',
			render: (text,record) => {
				if (record.pdfFile == null) {
					return <Tooltip title="Usted no ha adquirido el informe"><Icon type="lock"></Icon></Tooltip>
				}else{
					return getBases(text)
				}

			}
		},
		{
		  title: 'Reporte',
			dataIndex: 'pdfFile',
			key: 'pdfFile',
			render: (text,record,index) => {
				if (record.pdfFile !== null) {
					return (<a onClick={() => handleDownloadReportDetail(record)} ><Icon type="file-pdf" /></a>)
				} else {
					return (<Checkbox checked={record.checked} onChange ={handleOnChangeCheckPep(record)}/>)
				}
			}
		}
	]


	// const rowSelection = {
	// 	onChange: (selectedRowKeys, selectedRows) => {
	// 		console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
	// 		let details = selectedRows.map(row=>row.id)
	// 		setUboDetails(details)  //just details to api
	// 		setSubTotal(details.length*productsPrices.data[1].price)   //cambiar por filter
	// 		if (details.length*productsPrices.data[1].price < user.saldo) {
	// 			setNoSaldo(false)
	// 		}else{

	// 			setNoSaldo(true)
	// 		}
	// 	},
	// 	getCheckboxProps: record => ({
	// 		disabled: record.pdfFile !== null,
	// 		name: record.name,
	// 	}),
	// }

	const askForUbosTable = ()=> {
		setIsLoading(true)
		getDetalleSolicitudClientPromise(data.id).then((response)=>{
			setDetail(response.data)
			if(data.product.id === '1') {
				setRecords(response.data.ubos)
			}else {
				setRecords(response.data.psoc)
			}
			setIsLoading(false)
		})

	}

	const handleOnRowDetailPurchaseHistory = () =>{
		//getDetalleSolicitud
		setIsModalVisible(true)
		if (data.status == 'DELIVERED'){
			askForUbosTable()
		}

	}


	const buyUbos = async () => {
		const ubosIds = selectedUbos.reduce((acc,e)=>{
			acc.push(e.id)
			return acc
		},[])

		if (selectedUbos.length*product.price <= user.saldo){

			const amlOrder = {
				id : data.id,
				details : ubosIds
			}
			await purchaseDetailsClientPromise(amlOrder).then((response)=>{
				refreshUserHandler()
				setIsModalVisible(false)
				setIsConfirmModalVisible(false)
				setSubTotal(0)
				setSelectedUbos([])
				notification['success']({ message: t('messages.aml.successfulPurchase') })
			})
		}
	}


	const cancelConfirmPurchase = ()=> {
		setIsConfirmModalVisible(false)
	}

	const handleDownloadReportDetail = (detail)=> {
		window.open(apiConfig.url + '/report/getPDFDetail/' + detail.id + '.pdf')
	}

	const handleDownloadReportSolicitud = (sol)=> {
		window.open(apiConfig.url + '/report/getPDFSolicitud/' + sol.id + '.pdf')
	}

	const tableColumnsTree = [
		{
			title: 'Rut',
			dataIndex: 'rut',
			render: (text, record) => {
				return {
					props: {
						className: 'entity-type-'+record.type,   // there it is!
					},
					children: text
				}
			}
		},
		{
			title: t('messages.aml.name'),
			dataIndex: 'nombre',
			render: (text, record) => {
				return {
					props: {
						className: 'entity-type-'+record.type,   // there it is!
					},
					children: camelizerHelper(text)
				}
			},
			sorter: (a, b) => a.nombre.localeCompare(b.nombre)
		},
		{
			title: 'Porcentaje',
			dataIndex: 'porcentaje',
			render: (text) => {
				if (text !== null) {
					return (Math.round(text * 100)/100) + '%'
				}
			},
			sorter: (a, b) => a.porcentaje - b.porcentaje
		},
		{
			title: 'Tipo',
			dataIndex: 'tipo',
			render: (text) => camelizerHelper(text)
		}
	]

	const getDataTree = (propietarios, level) => {
		if( propietarios !== undefined && propietarios !== null && propietarios.length > 0) {
			const list = []
			for (let i = 0; i < propietarios.length; i++) {
				list.push({ key: i+propietarios[i].id+level, rut: (propietarios[i].pais === 'NACIONAL' ? propietarios[i].rut : propietarios[i].pais), nombre: propietarios[i].name, porcentaje: propietarios[i].participacionDirecta, type: propietarios[i].type, tipo: propietarios[i].relation, children: getDataTree(propietarios[i].propietarios, level+1) })
			}
			return list
		}
	}

	const getBases = (baseAml) => {
		if(baseAml !== null && baseAml !== '' && baseAml !== undefined) {
			let bases = baseAml.split(',')
			let ret = []
			bases.forEach(base => {
				if(base === 'PEP') ret.push('PEP')
				else if(base === 'FP') ret.push('Funcionario Público')
				else if(base === 'PEPH') ret.push('PEP Histórico')
				else if(base === 'PEPC') ret.push('Candidato')
				else if(base === 'PERSON') ret.push('Persona de Interés')
				else if(base === 'WL') ret.push('WatchList')
				else if(base === 'SOC') ret.push('State owned company')
				else if(base === 'AME') ret.push('Adverse media')
			})
			return ret.join(', ')
		}
		return null
	}
	return (
		<div className="purchase-item" >

			<div className = "purchase-list">

				<Row onClick = {handleOnRowDetailPurchaseHistory} >
					<Col span={3} >
						<Tooltip title = {data.product.name}>
							<div className="avatar">
								{ data.product.id === '1' ?
									<img src={ networkImg } alt={ data.type } />
									:
									<>
										{ data.product.id === '2' ?
											<img src={ ownersImg } alt={ data.type } />
											:
											<Icon type="file-done" className="img-avatar" />
										}
									</>
								}
							</div>
						</Tooltip>
					</Col>
					<Col span={3}>
						{ data.transaction.transactionNro }
					</Col>
					<Col span={3}>
						{ moment(data.creationDate).format("DD-MM-YYYY") }
					</Col>
					<Col span={3}>
						{ data.rut }
					</Col>
					<Col span={ 6 }>
						{ data.name !== null ? camelizerHelper(data.name): "NA" }
					</Col>
					{ type == '1' ?
						<Col span={ 3 }>
							{ data.fecMalla !== null ?  moment(data.fecMalla).format("DD-MM-YYYY") : "NA" }
						</Col>
						:
						<Col span={ 3 }>
							{data.parent?.rut }
						</Col>
					}
					<Col span={ 3 }>
						{ t('messages.aml.pagos.type.' + data.status) }
					</Col>
				</Row>
			</div>
			{/* style: {display: (data.product.id === '1' && data.status == 'DELIVERED' ? 'display': 'none')} */}

			<div className="purchase-modal">
				<Modal
					title="Detalle de Solicitud"
					visible={isModalVisible}
					onCancel={handleCancel}
					onOk = {handleOk}
					okButtonProps={{
						type: 'primary',
						className: "purchase-ok-button",
						disabled: (selectedUbos.length === 0),
						style: {display:'none'}
					}}
					cancelText = "Cerrar"
					okText = "Comprar"
					width = {950}
					style = { type === 1 && data.status == 'DELIVERED' ? {top:'10px'} : {}}
				>

					<Row>
						<Col span = {12}>

						</Col>
						<Col span = {6} style = {{textAlign:"right"}}>
							<strong>
							Nro Orden:
							</strong>
							&nbsp;&nbsp;{data.transaction.transactionNro}
						</Col>

						<Col span = {6} style = {{textAlign:"right"}}>
							<strong>
							Nro solicitud:
							</strong>
							&nbsp;&nbsp;{data.orderId}
						</Col>
					</Row>
					<br/>

					{ data.product.id === '3' && data.parent &&
						<>
							<h3>Origen: {data.parent.product.name }</h3>
							<Descriptions size="small" column={2} bordered>
								<Descriptions.Item label="Nombre o Razon Social">{data.parent!== null ? camelizerHelper(data.parent.name) : "" }</Descriptions.Item>
								<Descriptions.Item label="Rut">{data.parent!== null ? data.parent.rut : "" }</Descriptions.Item>
							</Descriptions>
							<br/>
							<h3>Beneficiario Final </h3>
						</>
					}
					<Descriptions size="small" column={2} bordered>
						<Descriptions.Item label="Producto">{ data.product.name }</Descriptions.Item>
						<Descriptions.Item label="Estado">{ t('messages.aml.pagos.type.' + data.status) }</Descriptions.Item>
						<Descriptions.Item label={ data.product.id === '3' ? 'Nombre' : 'Nombre o Razón Social' }>{ data.name !== null ? camelizerHelper(data.name): "NA" }</Descriptions.Item>
						<Descriptions.Item label="Rut">{ data.rut }</Descriptions.Item>
						<Descriptions.Item label="Fecha de solicitud">{ moment(data.creationDate).format("DD-MM-YYYY") } </Descriptions.Item>
						{ data.product.id === '1' &&
							<Descriptions.Item label="Fecha de actualización">{ data.fecMalla !== null ?  moment(data.fecMalla).format("DD-MM-YYYY") : "NA" }</Descriptions.Item>
						}
						{ data.product.id === '3' &&
							<Descriptions.Item label="Listas Compliance" span={2}>{ getBases(detail.baseAml) }</Descriptions.Item>
						}
						{ data.hasPdf &&
							<Descriptions.Item label="Reporte"><Button icon="file-pdf" onClick={() => handleDownloadReportSolicitud(data)}>Descargar PDF</Button></Descriptions.Item>
						}

					</Descriptions>

					{data.product.id === '1' && data.status === 'DELIVERED' &&
						<>
							{ isLoading ?
								<Spin className = "modal-purchase-spinner"/>
								:
								<>
									<Tabs>
							      <TabPane className = "beneFinales" tab="Beneficiarios finales" key="1">
											<Table dataSource={records} columns={columns} size="small" pagination={{pageSize: 5,position:'bottom'}} />
											<Row style={{ borderTop: '1px solid #e8e8e8', paddingTop: '5px' }}>
												<Col span = {20}>
													<div
														dangerouslySetInnerHTML={{__html:
															t('messages.aml.pepList',
																{ amlProduct: '<strong>'+product.name+'</strong>',
																	amlPrice: '<strong>$ '+numberFormatterHelper(product.price)+'</strong>'
																}
															)
														}}
													  style = {{textAlign:"justify"}}
													/>
												</Col>
												<Col span = {4}>
													<h3 className="subTotal" style = {{margin:'0px',textAlign:'right', paddingRight:'10px' }}>
														{
															selectedUbos.length > 0 ?
																<>
															Total: $ {numberFormatterHelper(subTotal)}
																</>
																:
																<>
														&nbsp;
																</>
														}
													</h3>
													<br/>
													<div style = {{textAlign: "right"}}>
														<Button onClick = {handleOk} type = "primary" disabled={ selectedUbos.length === 0}>
															 Comprar
														</Button>
													</div>
												</Col>
											</Row>
							      </TabPane>
							      <TabPane tab="Malla de Propiedad" key="2">
											<Table columns={ tableColumnsTree } dataSource={ getDataTree(detail.malla, 0) } size="small" pagination={{pageSize: 5,position:'bottom'}} />
							      </TabPane>
							    </Tabs>
								</>
							}
						</>
					}
					{data.product.id === '2' && data.status === 'DELIVERED' &&
						<>
							{ isLoading ?
								<Spin className = "modal-purchase-spinner"/>
								:
								<>
									<Tabs>
							      <TabPane className = "beneFinales" tab="Participacion en Sociedades" key="1">
											<Table dataSource={records} columns={columns} size="small" pagination={{pageSize: 5,position:'bottom'}} />
											<Row style={{ borderTop: '1px solid #e8e8e8', paddingTop: '5px' }}>
												<Col span = {20}>
													<div
														dangerouslySetInnerHTML={{__html:
															t('messages.aml.pepListPart',
																{ amlProduct: '<strong>'+product.name+'</strong>',
																	amlPrice: '<strong>$ '+product.price+'</strong>'
																}
															)
														}}
													  style = {{textAlign:"justify"}}
													/>
												</Col>
												<Col span = {4}>
													<h3 className="subTotal" style = {{margin:'0px',textAlign:'right', paddingRight:'10px' }}>
														{
															selectedUbos.length > 0 ?
																<>
															Total: $ {numberFormatterHelper(subTotal)}
																</>
																:
																<>
														&nbsp;
																</>
														}
													</h3>
													<br/>
													<div style = {{textAlign: "right"}}>
														<Button onClick = {handleOk} type = "primary" disabled={ selectedUbos.length === 0}>
															 Comprar
														</Button>
													</div>
												</Col>
											</Row>
							      </TabPane>
							    </Tabs>
								</>
							}
						</>
					}
				</Modal>
			</div>
			<ConfirmModal items = {selectedUbos} product = {product} confirmPurchase = {buyUbos} cancelConfirmPurchase = {cancelConfirmPurchase} isConfirmModalVisible = {isConfirmModalVisible} setActiveTabIndex = {setActiveTabIndex}/>
		</div>
	)
}

PurchaseHistoryItem.propTypes = {
	data: PropTypes.object.isRequired
}

export default withTranslation()(PurchaseHistoryItem)
