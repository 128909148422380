import { readNotificationService } from '../services'

export default (id) => {
	return new Promise((resolve, reject) => {
		readNotificationService(id)
			.then(() => {
				resolve({ success: true })
			})
			.catch(() => {
				reject({ error: true })
			})
	})
}
