import { Col, Row } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import NotFoundImg from './notfound.png'
const NotFound = () => (
<Row type='flex'>
    <Col span={24}>
        <Link to='/'>
            <div style={{width: '100%', textAlign:'center'}}>
                <img src={NotFoundImg} alt="NOT FOUND" width="80%"/>
            </div>
        </Link>
    </Col>
</Row>

)
export default NotFound;