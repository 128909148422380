import './Admin.scss'
import { Pagination, Row, Table, Tabs, Button } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { userContext } from '../../contexts';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getPaymentHistoryPromise, getPurchaseHistoryPromise } from './promises';
import moment from 'moment';
import apiConfig from '../../config/api';
import { ReportService } from '../../services';

import { Filters } from '../Dashboard/components';

const { TabPane } = Tabs

const Admin = ({history}) => {
  const size = 10;
  const [purchaseData, setPurchaseData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPagePurchase, setCurrentPagePurchase] = useState(0);
  const [currentPagePayment, setCurrentPagePayment] = useState(0);
  const [totalItemsPurchase, setTotalItemsPurchase] = useState(null);
  const [totalItemsPayment, setTotalItemsPayment] = useState(null);

  const { isLoggedIn, user } = useContext(userContext)
  const [activeFiltersPurchase, setActiveFiltersPurchase] = useState({})
  const [activeFiltersPayment, setActiveFiltersPayment] = useState({})

  useEffect(() => {
    if (!isLoggedIn || user?.userType !== "ADMIN"){
      history.push('/login')
    }else{
      handleSearchResultsPurchase(1)
      handleSearchResultsPayment(1)
    }
  }, []);

  const handleSearchResultsPurchase = async (currentPage, filters)=>{
		const fromNum = ((currentPage - 1) * size)
		setIsLoading(true)
		const searchResults = await getPurchaseHistoryPromise(fromNum, size, filters)
		setIsLoading(false)
		setPurchaseData(searchResults.records)
		setTotalItemsPurchase(searchResults.total)
		setCurrentPagePurchase(currentPage)
	}

  const handleSearchResultsPayment = async (currentPage, filters)=>{
		const fromNum = ((currentPage - 1) * size)
		setIsLoading(true)
		const searchResults = await getPaymentHistoryPromise(fromNum, size, filters)
		setIsLoading(false)
		setPaymentData(searchResults.records)
		setTotalItemsPayment(searchResults.total)
		setCurrentPagePayment(currentPage)
	}

  const handlePaginationPurchaseOnChange = async (currentPage)=>{
		handleSearchResultsPurchase(currentPage, activeFiltersPurchase)
	}

  const handlePaginationPaymentOnChange = async (currentPage)=>{
		handleSearchResultsPayment(currentPage, activeFiltersPayment)
	}

  const restoreFiltersPurchase = () => {
		setActiveFiltersPurchase({})
		handleSearchResultsPurchase(1)
	}

  const restoreFiltersPayment = () => {
		setActiveFiltersPayment({})
		handleSearchResultsPayment(1)
	}

  const getFiltersPurchase = (filters) => {
		setActiveFiltersPurchase(filters)
		handleSearchResultsPurchase(1, filters)
	}
  
  const getFiltersPayment = (filters) => {
		setActiveFiltersPayment(filters)
		handleSearchResultsPayment(1, filters)
	}

  const downloadBoleta = (payment) => {
    ReportService.read('/payment/getBoleta', { id: payment.id}, null, 'Boleta-' + payment.dteFolio + '.pdf')
  }

  const handleDownloadReport = (solicitud)=> {
		window.open(apiConfig.url + '/report/getPDFSolicitud/' + solicitud.id + '.pdf')
	}

  const columnsPurchase = [
    {
      title: 'Nro. Transacción',
      dataIndex: 'transactionNro'
    },
    {
      title: 'Fecha',
      dataIndex: 'creationDate',
      render: (text) => { 
        return moment(text).format('DD/MM/YYYY HH:mm')
      }
    },
    {
      title: 'Tipo de usuario',
      dataIndex: 'guestId',
      render: (text) => {
        return text ? 'Invitado' : 'Registrado'
      }
    },
    {
      title: 'Comprador',
      dataIndex: 'user',
      render: (text) =>{
        return <div>{text.name} <br/> {text.rut} <br/>{text.email}</div>
      }
    },
    {
      title: 'Producto',
      dataIndex: 'product.name',
    },
    {
      title: 'Total compra',
      dataIndex: 'amount',
      render: (amount) => amount * -1
    },
    {
      title: 'Método de pago',
      dataIndex: 'paymentMethod',
      render: (text) => {
        if(text === 'FLOW') return 'Flow'
        else if(text === 'WALLET') return 'Prepago'
      }
    },
    {
      title: 'Persona a consultar',
      dataIndex: 'solicitudes',
      render: (text, record) => {
        return text.map((item) => {
          return <div>
                  {item.name} <br/> 
                  {item.rut} &nbsp;
                  { item.hasPdf && item.status === 'DELIVERED' && <Button size='small' icon='file-pdf' onClick={() => handleDownloadReport(item)}/> }
                </div>
        })
      }
    },
  ];

  const columnsPayment = [
    {
      title: 'Id',
      dataIndex: 'id'
    },
    {
      title: 'Fecha',
      dataIndex: 'creationDate',
      render: (text) => { 
        return moment(text).format('DD/MM/YYYY HH:mm')
      }
    },
    {
      title: 'Comprador',
      render: (record) =>{
        return <div>{record.name} <br/> {record.rut} <br/>{record.email}</div>
      }
    },
    {
      title: 'Tipo',
      render: (record) => {
        return (
          <div>
            { record.origin === 'GUEST' ? 'Invitado' : 'Registrado' }
            <div> ID: {record.transaction?.transactionNro} </div>
          </div>
        )
      }
    },
    {
      title: 'Total pago',
      dataIndex: 'amount'
    },
    {
      title: 'Método de pago',
      dataIndex: 'provider',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      render: (status) => {
        if(status === 'SENT') return 'Pendiente'
        else if(status === 'PAID') return 'Pagado'
        else if(status === 'REFUSED') return 'Rechazado'
        else if(status === 'CANCELED') return 'Cancelado'
      }
    },
    {
      title: 'Folio boleta',
      dataIndex: 'dteFolio',
      render: (text, record) => {
        if(text) return <Button size="small" type="link" onClick={() => downloadBoleta(record)}>{text}</Button>
      }
    },
  ];

  return (
    <div className='admin-page'>
      <Tabs>
        <TabPane tab="Reportes" key="1">
          <Row>
            <Filters
              originPage='admin'
              onSetFilters={ getFiltersPurchase }
              onRestoreFilters = {restoreFiltersPurchase}
            />
          </Row>
          <div style={{padding: 20}}>
              <Table dataSource={purchaseData} columns={columnsPurchase} loading={isLoading} pagination={false} size="small"/>
          </div>
          <div className='pagination-element-admin'>
            <Pagination current={currentPagePurchase} total={totalItemsPurchase} onChange={ handlePaginationPurchaseOnChange } pageSize={ size } />
          </div>
        </TabPane>
        <TabPane tab="Pagos" key="2">
          <Row>
            <Filters
              originPage='admin-pagos'
              onSetFilters={ getFiltersPayment }
              onRestoreFilters = {restoreFiltersPayment}
            />
          </Row>
          <div style={{padding: 20}}>
              <Table dataSource={paymentData} columns={columnsPayment} loading={isLoading} pagination={false} size="small"/>
          </div>
          <div className='pagination-element-admin'>
            <Pagination current={currentPagePayment} total={totalItemsPayment} onChange={ handlePaginationPaymentOnChange } pageSize={ size } />
          </div>
        </TabPane>
      </Tabs>
    </div>
  )
}

Admin.propTypes = {
	history: PropTypes.object.isRequired
}

export default withRouter(Admin)
