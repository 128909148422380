import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

export default {
	sendForm: (name, email, phone, companyName, productDetail) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/guest/createDemoRequest',
			method: 'post',
			body: {
				name,
                email,
                phone,
                companyName,
                productDetail
			}
		})
	},
}
