import React, {useState,useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import { activateSaveRegisterClientPromise } from './components/promises'
import {withTranslation} from 'react-i18next'
import { notification } from 'antd'
import './activate.scss'

const Activate = ({history,t}) => {

	const [resp, setResp] = useState('')

	const path = history.location.pathname

	const activatePromise = async (tokenToApi)=>{
		const response = await activateSaveRegisterClientPromise(tokenToApi)
		setResp(response)
	}

	useEffect(()=>{
		const tokenToApi = {id: path.substring(path.lastIndexOf('/')+1)}
		activatePromise(tokenToApi)
	},[])

	return(
		<div className = "activate">
			<h1>
				{
					resp.status === 'OK' ?
						t('messages.aml.userActivateOK')
					:
					(()=>{
						switch (resp.msg) {
              case "NOT_EXIST":   return t('messages.aml.usernameDoesNotExists')
						  case "USER_ACTIVATED":   return t('messages.aml.userActivated')
						  default: return resp.msg
						}
					})()
				}
			</h1>

		</div>
	)
}

Activate.propTypes = {
	history: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
}


export default withTranslation()(withRouter(Activate))
