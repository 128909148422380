import { notification } from 'antd';
import { payRequestService } from '../services'

const payRequestPromise = (requestJson) => {
    return new Promise(async (resolve, reject) => {
    await payRequestService.startPay(requestJson)
        .then(response => resolve(response.data))
        .catch(err => notification.error({
            message: "Se ha producido un error"
        }) )
        // reject({ error: true }))
    })
}

export default payRequestPromise;