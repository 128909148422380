import './home.scss'
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, Icon, Input, Row, Tooltip, notification } from 'antd'
import { makeNewRequestPromise } from '../../../../promises'
import { validateCompanyRutHelper, validateRutHelper } from '../../../../../../helpers'
import meshImage from '../../../../../Landing/components/Products/mesh.png'
import societyImage from '../../../../../Landing/components/Products/society.png'
import shareHoldersImage from '../../../../../Landing/components/Products/shareholders.png'

import { userContext,activeTabIndexContext } from '../../../../../../contexts'
import {ConfirmModal} from '../../../'
import { useTranslation } from 'react-i18next'

const Home = ({ form }) => {
	const { productsPrices, refreshUserHandler } = useContext(userContext)
	const [productMesh, setProductMesh] = useState({price: 0})
	const [productShareHolders, setProductShareHolders] = useState({price: 0})
	const [productAML, setProductAML] = useState({price: 0})
	const [selectedProductId, setSelectedProductId] = useState(null)
	const [meshHasErrors, setMeshHasErrors] = useState(false)
	const [meshInputs, setMeshInputs] = useState([])
	const [shareHoldersHasErrors, setShareHoldersHasErrors] = useState(false)
	const [shareHoldersInputs, setShareHoldersInputs] = useState([])
	const [amlHasErrors, setAmlHasErrors] = useState(false)
	const [amlInputs, setAmlInputs] = useState([])
	const [isConfirmPurchaseModalVisible, setIsConfirmPurchaseModalVisible] = useState(false)

	const { getFieldDecorator } = form
	const { t } = useTranslation()
	const { tabIndexHandler } = useContext(activeTabIndexContext)


	useEffect(() => {
		handleAddMesh()
		handleAddShareHolder()
		handleAddAML()
		for(let i=0;i<productsPrices.data.length;i++) {
			if(productsPrices.data[i].id === '1') {
				setProductMesh(productsPrices.data[i])
			}else if(productsPrices.data[i].id === '2') {
				setProductShareHolders(productsPrices.data[i])
			}else if(productsPrices.data[i].id === '3') {
				setProductAML(productsPrices.data[i])
			}
		}
	}, [])

	const buildUniqueId = () => {
		return Math.random().toString(36).slice(2)
	}

	const handleAddMesh = () => {
		const id = buildUniqueId()

		const mesh = {
			id: 'mesh-' + id + '-id',
			isValid: -1,
			key: 'mesh-' + id + '-key',
			rut: ''
		}

		setMeshInputs([...meshInputs, mesh])
	}

	const handleOnChangeMesh = () => {
		setMeshHasErrors(false)
	}

	const handleBlurMesh = async (id, index) => {
		const rut = document.getElementById(id).value

		if (validateCompanyRutHelper(rut)) {
			await setMeshInputs(list => {
				const inputs = JSON.parse(JSON.stringify(list))

				inputs[index].isValid = true
				inputs[index].rut = rut

				return inputs
			})
		}else {
			await setMeshInputs(list => {
				const inputs = JSON.parse(JSON.stringify(list))

				inputs[index].isValid = false

				return inputs
			})
		}
	}

	const handleDeleteMesh = async (index) => {
		await setMeshInputs(items => {
			const newItems = JSON.parse(JSON.stringify(items))

			newItems.splice(index, 1)

			return newItems
		})
	}

	const handleSubmitMesh = async (e) => {
		e.preventDefault()

		const items = []

		for (let i = 0; i < meshInputs.length; i++) {
			items.push(meshInputs[i].id)
		}

		await form.validateFields(items)

		let errors = 0

		for (let i = 0; i < meshInputs.length; i++) {
			if (!meshInputs[i].isValid) {
				errors++
			}
		}

		if (errors > 0) {
			setMeshHasErrors(true)
		} else {
			await setSelectedProductId(1)
			await setIsConfirmPurchaseModalVisible(true)
		}
	}

	const handleAddShareHolder = async () => {
		const id = buildUniqueId()

		const mesh = {
			id: 'shareholder-' + id + '-id',
			isValid: null,
			key: 'shareholder-' + id + '-key',
			rut: ''
		}

		setShareHoldersInputs([...shareHoldersInputs, mesh])
	}

	const handleOnChangeShareHolders = () => {
		setShareHoldersHasErrors(false)
	}

	const handleBlurShareHolder = async (id, index) => {
		const rut = document.getElementById(id).value

		if (validateRutHelper(rut)) {
			await setShareHoldersInputs(list => {
				const inputs = JSON.parse(JSON.stringify(list))

				inputs[index].isValid = true
				inputs[index].rut = rut

				return inputs
			})
		} else {
			await setShareHoldersInputs(list => {
				const inputs = JSON.parse(JSON.stringify(list))

				inputs[index].isValid = false

				return inputs
			})
		}
	}

	const handleDeleteShareHolder = async (index) => {
		await setShareHoldersInputs(items => {
			const newItems = JSON.parse(JSON.stringify(items))

			newItems.splice(index, 1)

			return newItems
		})
	}

	const handleSubmitShareHolder = async (e) => {
		e.preventDefault()

		const items = []

		for (let i = 0; i < shareHoldersInputs.length; i++) {
			items.push(shareHoldersInputs[i].id)
		}

		await form.validateFields(items)

		let errors = 0

		for (let i = 0; i < shareHoldersInputs.length; i++) {
			if (!shareHoldersInputs[i].isValid) {
				errors++
			}
		}

		if (errors > 0) {
			setShareHoldersHasErrors(true)
		} else {
			await setSelectedProductId(2)
			await setIsConfirmPurchaseModalVisible(true)
		}
	}

	const handleAddAML = () => {
		const id = buildUniqueId()

		const aml = {
			id: 'aml-' + id + '-id',
			isValid: -1,
			key: 'aml-' + id + '-key',
			rut: ''
		}

		setAmlInputs([...amlInputs, aml])
	}

	const handleOnChangeAML = () => {
		setAmlHasErrors(false)
	}

	const handleBlurAML = async (id, index) => {
		const rut = document.getElementById(id).value

		if (validateRutHelper(rut)) {
			await setAmlInputs(list => {
				const inputs = JSON.parse(JSON.stringify(list))

				inputs[index].isValid = true
				inputs[index].rut = rut

				return inputs
			})
		}else {
			await setAmlInputs(list => {
				const inputs = JSON.parse(JSON.stringify(list))

				inputs[index].isValid = false

				return inputs
			})
		}
	}

	const handleDeleteAML = async (index) => {
		await setAmlInputs(items => {
			const newItems = JSON.parse(JSON.stringify(items))

			newItems.splice(index, 1)

			return newItems
		})
	}

	const handleSubmitAML = async (e) => {
		e.preventDefault()

		const items = []

		for (let i = 0; i < amlInputs.length; i++) {
			items.push(amlInputs[i].id)
		}

		await form.validateFields(items)

		let errors = 0

		for (let i = 0; i < amlInputs.length; i++) {
			if (!amlInputs[i].isValid) {
				errors++
			}
		}

		if (errors > 0) {
			setAmlHasErrors(true)
		} else {
			await setSelectedProductId(3)
			await setIsConfirmPurchaseModalVisible(true)
		}
	}

	const handleConfirmPurchase = async () => {
		let items = []

		if (selectedProductId === 1) {
			await meshInputs.map(input => items.push(input.rut))
		}else if (selectedProductId === 2) {
			await shareHoldersInputs.map(input => items.push(input.rut))
		}else if (selectedProductId === 3) {
			await amlInputs.map(input => items.push(input.rut))
		}

		await makeNewRequestPromise(selectedProductId, items).then(async (response)=>{
			if (response.data === "success"){
				await refreshUserHandler()
				await setIsConfirmPurchaseModalVisible(false)
				tabIndexHandler(1)
				refreshUserHandler()
				notification['success']({ message: t('messages.aml.successfulPurchase') })
	    }else if (response.data == "INSUFFICIENT_BALANCE"){
				notification['error']({ message: t('messages.aml.youJustDontHaveEnoughBalance') })
			} 
		})

	}

	const handleCancelPurchase = () => {
		setIsConfirmPurchaseModalVisible(false)
	}

	return (
		<div className="dashboard-home">
			<div className="products">
				<Row className='productos' gutter={[10]} type="flex">
					<Col xs={24} lg={8}>
						<div className="product" style={{paddingBottom: 41 + 78 + (meshInputs.length * 32)}}>
							<Form name="meshForm" onSubmit={ handleSubmitMesh }>
								<div id="mesh" className="product-header">
									<img src={ meshImage } alt="" />
								</div>
								<div className="product-description">
									<div className="product-title">
										<div className="product-title-inner">
											{productsPrices.data.find(p => p.id === '1').name}
										</div>
									</div>
									<p>Acceda a información actualizada y conozca quienes son los <Tooltip placement="top" title={ <p><strong>Dueño final</strong> : <br />Aquella persona natural que finalmente posee, directa o indirectamente, una participación en la empresa. Una participación indirecta tiene lugar cuando el control de la sociedad se ejerce a través de una sociedad y no directamente.</p> }><span>dueños finales</span></Tooltip> de una empresa.</p>
								</div>
								<div className="product-features">
									<ul>
										<li><Icon type="check" /> Malla de Propiedad con todos los niveles incluyendo el RUT, Nombre y Participación de cada beneficiario final.</li>
										<li><Icon type="check" /> Identificación de empresas dueñas de otras empresas y sus participaciones.</li>
										<li><Icon type="check" /> Información verificada de fuentes públicas.</li>
										<li><Icon type="check" /> Monitoreo de riesgo solo a los Beneficiarios Finales (Mayor al 10% de participación).</li>
										<li><Icon type="plus-circle" theme="filled" style={{color:'#3B8FCA'}} /> <strong>Servicio adicional</strong><div>Verifica si los beneficiarios finales son PEP o figuran en listas de riesgos.</div></li>
									</ul>
								</div>
								<div className="product-footer" style={{ paddingTop: meshHasErrors ? 45 : 20 }}>
									{ meshHasErrors &&
										<div className="rut-not-valid-error">
											RUT inválido(s) encontrado(s).
										</div>
									}
									<Row>
										<Col span={ 24 }>
											<ul id="product-mesh" className="input-collection">
												{
													meshInputs.map((item, index) =>
														<li key={ item.key }>
															<Form.Item>
																{ getFieldDecorator(item.id, {
																	rules: [{
																		required: true,
																		message: 'Campo obligatorio'
																	}]
																})(<Input placeholder="RUT de persona jurídica ..." onBlur={ () => handleBlurMesh(item.id, index) } onChange={ handleOnChangeMesh } />) }
															</Form.Item>
															<div className="add-item action-item">
																<Tooltip placement="top" title="Agregar RUT adicional" onClick={ handleAddMesh }>
																	<Icon type="plus" />
																</Tooltip>
															</div>
															<div className="delete-item action-item">
																<Tooltip placement="top" title="Eliminar RUT" onClick={ () => handleDeleteMesh(index) }>
																	<Icon type="close" />
																</Tooltip>
															</div>
															{
																item.isValid === true &&
																	<div className="is-valid">
																		<Icon type="check" />
																	</div>
															}
															{
																item.isValid === false &&
																	<div className="is-valid not-valid">
																		<Icon type="close" />
																	</div>
															}
														</li>
													)
												}
											</ul>
											<Button htmlType="submit" type="primary">Solicitar Malla{ meshInputs.length > 1 ? 's' : '' }</Button>
										</Col>
									</Row>
								</div>
							</Form>
							<div className="product-total-price">
								Precio total x <strong>{ meshInputs.length }</strong> unidad{ meshInputs.length > 1 ? 'es' : '' } : <strong>$ { (productMesh.price * meshInputs.length).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</strong>
							</div>
						</div>
					</Col>
					<Col xs={24} lg={8}>
						<div className="product" style={{paddingBottom: 41 + 78 + (shareHoldersInputs.length * 32)}}>
							<Form onSubmit={ handleSubmitShareHolder }>
								<div className="product-header">
									<img src={ societyImage } alt="" />
								</div>
								<div className="product-description">
									<div className="product-title">
										<div className="product-title-inner">
											{productsPrices.data.find(p => p.id === '2').name}
										</div>
									</div>
									<p>Acceda a información actualizada y conozca en que empresa(s) participa una persona natural o jurídica.</p>
								</div>
								<div className="product-features">
									<ul>
										<li><Icon type="check" /> Participación en sociedades de una persona, identificando la Razón Social, RUT y % de participación.</li>
										<li><Icon type="check" /> Identificación de la totalidad de empresas en las que participa la persona</li>
										<li><Icon type="check" /> Información verificada de fuentes públicas.</li>
										<li><Icon type="plus-circle" theme="filled" style={{color:'#3B8FCA'}} /> <strong>Servicio adicional</strong><div>Verifica si las Personas Jurídicas son PEP o figuran en listas de riesgos.</div></li>
									</ul>
								</div>
								<div className="product-footer" style={{ paddingTop: shareHoldersHasErrors ? 45 : 20 }}>
									{ shareHoldersHasErrors &&
										<div className="rut-not-valid-error">
											RUT inválido(s) encontrado(s).
										</div>
									}
									<Row>
										<Col span={ 24 }>
											<ul className="input-collection">
												{
													shareHoldersInputs.map((item, index) =>
														<li key={ item.key }>
															<Form.Item>
																{ getFieldDecorator(item.id, {
																	rules: [{
																		required: true,
																		message: 'Campo obligatorio'
																	}]
																})(<Input placeholder="RUT de persona natural o jurídica ..." onBlur={ () => handleBlurShareHolder(item.id, index) } onChange={ handleOnChangeShareHolders } />) }
															</Form.Item>
															<div className="add-item action-item">
																<Tooltip placement="top" title="Agregar RUT adicional" onClick={ handleAddShareHolder } defaultVisible="true">
																	<Icon type="plus" />
																</Tooltip>
															</div>
															<div className="delete-item action-item">
																<Tooltip placement="top" title="Eliminar RUT" onClick={ () => handleDeleteShareHolder(index) }>
																	<Icon type="close" />
																</Tooltip>
															</div>
															{
																item.isValid === true &&
																	<div className="is-valid">
																		<Icon type="check" />
																	</div>
															}
															{
																item.isValid === false &&
																	<div className="is-valid not-valid">
																		<Icon type="close" />
																	</div>
															}
														</li>
													)
												}
											</ul>
											<Button htmlType="submit" type="primary">Solicitar Participaci{ shareHoldersInputs.length > 1 ? 'ones' : 'ón' }</Button>
										</Col>
									</Row>
								</div>
							</Form>
							<div className="product-total-price">
								Precio total x <strong>{ shareHoldersInputs.length }</strong> unidad{ shareHoldersInputs.length > 1 ? 'es' : '' } : <strong>$ { (productShareHolders.price * shareHoldersInputs.length).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</strong>
							</div>
						</div>
					</Col>
					<Col xs={24} lg={8}>
						<div className="product" style={{paddingBottom: 41 + 78 + (amlInputs.length * 32)}}>
							<Form onSubmit={ handleSubmitAML }>
								<div className="product-header">
									<img src={ shareHoldersImage } alt="" />
								</div>
								<div className="product-description">
									<div className="product-title">
										<div className="product-title-inner">
											{productsPrices.data.find(p => p.id === '3').name}
										</div>
									</div>
									<p>Verifica si tu cliente es una Persona Políticamente Expuesta o está en una lista de riesgo.</p>
								</div>
								<div className="product-features">
									<ul>
										<li><Icon type="check" /> Podrá saber si la persona consultada es PEP o está relacionada con un PEP.</li>
										<li><Icon type="check" /> Podrá saber si la persona consultada es Funcionario Público de alguna repartición del estado.</li>
										<li><Icon type="check" /> Búsqueda de personas de interés.</li>
										<li><Icon type="check" /> Búsqueda en listas internacionales.</li>
									</ul>
								</div>
								<div className="product-footer" style={{ paddingTop: amlHasErrors ? 45 : 20 }}>
									{ amlHasErrors &&
										<div className="rut-not-valid-error">
											RUT inválido(s) encontrado(s).
										</div>
									}
									<Row>
										<Col span={ 24 }>
											<ul className="input-collection">
												{
													amlInputs.map((item, index) =>
														<li key={ item.key }>
															<Form.Item>
																{ getFieldDecorator(item.id, {
																	rules: [{
																		required: true,
																		message: 'Campo obligatorio'
																	}]
																})(<Input placeholder="RUT de persona natural o jurídica ..." onBlur={ () => handleBlurAML(item.id, index) } onChange={ handleOnChangeAML } />) }
															</Form.Item>
															<div className="add-item action-item">
																<Tooltip placement="top" title="Agregar RUT adicional" onClick={ handleAddAML } defaultVisible="true">
																	<Icon type="plus" />
																</Tooltip>
															</div>
															<div className="delete-item action-item">
																<Tooltip placement="top" title="Eliminar RUT" onClick={ () => handleDeleteAML(index) }>
																	<Icon type="close" />
																</Tooltip>
															</div>
															{
																item.isValid === true &&
																	<div className="is-valid">
																		<Icon type="check" />
																	</div>
															}
															{
																item.isValid === false &&
																	<div className="is-valid not-valid">
																		<Icon type="close" />
																	</div>
															}
														</li>
													)
												}
											</ul>
											<Button htmlType="submit" type="primary">Solicitar Reporte{ amlInputs.length > 1 ? 's' : '' }</Button>
										</Col>
									</Row>
								</div>
							</Form>
							<div className="product-total-price">
								Precio total x <strong>{ amlInputs.length }</strong> unidad{ amlInputs.length > 1 ? 'es' : '' } : <strong>$ { (productAML.price * amlInputs.length).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }</strong>
							</div>
						</div>
					</Col>
				</Row>
			</div>

			<ConfirmModal items = {selectedProductId == 1 ? meshInputs : selectedProductId == 2 ? shareHoldersInputs : amlInputs} product = {selectedProductId == 1 ? productMesh : selectedProductId == 2 ? productShareHolders : productAML} confirmPurchase={handleConfirmPurchase} cancelConfirmPurchase={handleCancelPurchase} isConfirmModalVisible= {isConfirmPurchaseModalVisible} />





		</div>
	)
}

Home.propTypes = {
	form: PropTypes.object.isRequired
}

export default  Form.create()(Home)
