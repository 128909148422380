import 'antd/dist/antd.css'
import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import HomePage from './containers/home/Home'
import Register from './containers/register/Register'
import { authTokenValidatorHelper, sessionStorageCleanerHelper, authTokenRenewerHelper } from './helpers'
import { getProductsPricesPromise, logoutPromise,changePasswordPromise } from './promises'
import { I18nextProvider } from 'react-i18next'
import i18nextConfig from './config/i18next'
import { userContext } from './contexts'
import { Layout } from './layouts'
import NotFound from './containers/notFound/NotFound'
import { DashboardPage, LandingPage, LoginPage, BuyingPage, ServiceFormPage, AdminPage } from './pages'
import { getUserPromise } from './pages/Login/promises'
import { ActivatePage } from './pages/index'
import { ModalChangePassword } from './layouts/Default/components'
import { Spin, message } from 'antd';
import { LocalStorageService } from './services';

class App extends Component {
  state = {
    user: { saldo: 0 },
    isLoading: true,
    isLoggedIn: false,
    productsPrices: [],
    isModalChangePasswordVisible: false,
    handlerTabActive: null
  }

  async componentDidMount() {
		const productsPrices = await getProductsPricesPromise()
		await this.setState({ productsPrices })

    const isValidAuthToken = await authTokenValidatorHelper()
    if (isValidAuthToken) {
      const user = await getUserPromise()
      
      if(user) {
        const userType = LocalStorageService.read("userType")
        if (!(userType && (userType === user.type))) {
          message.error("Error, acceso vulnerado ");
        } else {
          await this.setState({
            user,
            isLoggedIn: true
          })
        }
      }
    }
    this.setState({ isLoading: false })
  }

  async handlerSetTabActive(handler){
    await this.setState({handlerTabActive: handler})
  }

  async handleLogin(user) {
    const isActivated = user.feActivacion !== null
    if (!isActivated) {
      //this.handleOpenModalChangePassword()
    }

    const userType = LocalStorageService.read("userType")
    if (!(userType && (userType === user.type))) {
        message.error("Error, imposible validar login!");
    } else {
      await this.setState({
        user,
        isLoggedIn: true
      })
      new authTokenRenewerHelper(this.handleLogout.bind(this))
    }
  }

  async handleLogout() {
    await logoutPromise()
    await sessionStorageCleanerHelper()

		await this.setState({ isLoggedIn: false })

		window.setTimeout(async () => {
			await this.setState({
	      user: { saldo: 0 },
	      isLoading: false,
	    })
		}, 500)
  }

	async handleRefreshUser() {
		const user = await getUserPromise()

		await this.setState({ user })
	}

  async handleOpenModalChangePassword() {
    this.setState({ isModalChangePasswordVisible: true })
  }

  async handleCloseModalChangePassword() {
    this.setState({ isModalChangePasswordVisible: false })
  }


  async handleSaveChangePassword(passwordCurrent, passwordNew, passwordNewConfirm) {
    await changePasswordPromise(passwordCurrent, passwordNew, passwordNewConfirm)

    this.setState({ isModalChangePasswordVisible: false })
  }



  render() {
    const { user, isLoading, isLoggedIn, productsPrices, isModalChangePasswordVisible, handlerTabActive } = this.state

    if (isLoading && user) {
      return <div style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: 200}}><div style={{fontWeight: 700, fontSize: 35}}>LOADING <Spin size='large'/></div></div>
    } else {
      return (
        <I18nextProvider i18n={ i18nextConfig }>
          <userContext.Provider value={{ user, handlerTabActive, handlerSetTabActive: this.handlerSetTabActive.bind(this), loginHandler: this.handleLogin.bind(this), logoutHandler: this.handleLogout.bind(this), isLoggedIn, productsPrices, refreshUserHandler: this.handleRefreshUser.bind(this) }}>
            <Router>
              <Layout.Default>
                <Switch>
                  <Route path="/register" component={ Register } />
                  <Route path="/activate" component={ ActivatePage } />
                  <Route path="/login" render={ LoginPage } />
                  <Route path="/dashboard/:tab?/:status?" exact render={ DashboardPage } />
                  <Route exact path="/" component={ LandingPage } />
                  <Route path="/buyPage/:prod/:paymentId?" component={BuyingPage}/>
                  <Route path="/serviceContact/:typeOfService" component={ServiceFormPage}/>
                  <Route exact path='/admin' component={AdminPage} />
                  <Route render={ NotFound } />
                </Switch>
                <ModalChangePassword
                  visible={ isModalChangePasswordVisible }
                  onOk={ this.handleSaveChangePassword.bind(this) }
                  onCancel={ this.handleCloseModalChangePassword.bind(this) }
                  isForced
                />
              </Layout.Default>
            </Router>
          </userContext.Provider>
        </I18nextProvider>
      )
    }
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
