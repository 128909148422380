import React from "react"
import { Col, Row } from "antd"
import "./mainDiv.scss"
import { LeftVideo, RightForm } from "./components"

const MainDiv = () => {
	return (
		<div className="mainDiv">
			<Row type="flex" style={{alignItems: 'center'}} gutter={[0, 20]}>
				<Col xs={24} lg = {16}>
					<LeftVideo />
				</Col>
				<Col xs={24} lg = {8}>
					<RightForm />
				</Col>
			</Row>
		</div>
	)
}

export default MainDiv
