import './logo.scss'
import React from 'react'
import logo from './gesintel.gif'

const Logo = () => {
	return (
		<div className="logo">
			<img src={ logo } alt="Gesintel Compliance Logo" />
		</div>
	)
}

export default Logo
