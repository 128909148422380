import './paymenthistory.scss'
import React, { useEffect, useState } from 'react'
import { Col, Row,Pagination, Spin } from 'antd'
import { Item,Filters } from './components'
import { getTransaccionesPaymentHistoryClientPromise } from './promises'
import zeroItemsImg from './zero-items.png'


const PaymentHistory = () => {
	const [paymentHistory, setPaymentHistory] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [resultsTotalNum, setResultsTotalNum] = useState(0)
	const [resultsPerPage, setResultsPerPage] = useState(10)
	const [isLoading,setIsLoading] = useState(false)
	const [activeFilters,setActiveFilters] = useState({})


	const getFilters = (filters) => {
		setActiveFilters(filters)
		handleSearchResults(1, filters)
	}

	const restoreFilters = () => {
		setActiveFilters({})
		handleSearchResults(1)
	}

	const handlePaginationOnChange = async (currentPage)=>{
		handleSearchResults(currentPage, activeFilters)
	}

	const handleSearchResults = async (currentPage, filters)=>{
		const fromNum = ((currentPage - 1) * resultsPerPage)
		setIsLoading(true)
		const searchResults = await getTransaccionesPaymentHistoryClientPromise(fromNum, resultsPerPage,filters)
		setIsLoading(false)
		setPaymentHistory(searchResults.data.records)
		setResultsTotalNum(searchResults.data.total)
		setCurrentPage(currentPage)
	}

	useEffect(() => {
		handleSearchResults(1)
	}, [])

	return (
		<>

			<Filters
				onSetFilters={ getFilters }
				onRestoreFilters = {restoreFilters}
			/>

			<div className="payment-history">
				<div className="items-headers">
					<Row>
						<Col className = "first-col" span={ 3 }>
							Tipo
						</Col>
						<Col span={ 8 }>
							Descripción
						</Col>
						<Col span={ 4 }>
							Fecha
						</Col>
						<Col span={ 2 } style={{ textAlign: 'left' }}  >
							# Orden
						</Col>
						<Col span={ 3 } style={{ paddingRight: 15, textAlign: 'right' }}>
						  Monto
						</Col>
						<Col span={ 3 } style={{ paddingRight: 15, textAlign: 'right' }}>
							Saldo
						</Col>
					</Row>
				</div>
				<div className="items">
					{ isLoading ?
					  <div className ="spinner">
							<Spin spinning={ true } size="large" />
						</div>
						:
						<div className="items-inner">
							{
								paymentHistory.length > 0 ?
									paymentHistory.map((item, index) => <Item data={ item } key={ index } />)
									:
									<div className="zero-items">
										<img src={ zeroItemsImg } alt="" style={{ opacity: '0.3' }} />
										<big>Aún no tienes movimientos.</big>
									</div>
							}
						</div>
					}
				</div>
				<Pagination className="pagination" current={ currentPage } total={ resultsTotalNum } onChange={ handlePaginationOnChange } pageSize={ resultsPerPage } size="small" />
			</div>
		</>
	)
}

export default PaymentHistory
