import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { PurchaseHistory } from '..'

const { TabPane } = Tabs

const PurchaseHistoryTab = () => {
	return (
		<Tabs>
			<TabPane tab="Informes Mallas y Sociedades" key="1">
				<PurchaseHistory type={1} />
			</TabPane>
			<TabPane tab="Reportes Compliance" key="2">
				<PurchaseHistory type={2} />
			</TabPane>
		</Tabs>
	)
}

export default PurchaseHistoryTab
