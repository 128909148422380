import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

const auth = {
	getToken: (username, password, time) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/auth/authenticate',
			method: 'post',
			body: {
				username,
				password,
				time
			}
		})
	}
}

export default auth
