import apiConfig from '../../../../../../../config/api'
import { apiRequestorHelper } from '../../../../../../../helpers'

export default {
	save: (client) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/user/saveUser',
			method: 'post',
			body: client
		})
	},
}
