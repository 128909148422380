import './modalsuccessfulpurchase.scss'
import React, { useContext } from 'react'
import { Button, Icon, Modal } from 'antd'
import { userContext } from '../../../../contexts'
import { numberFormatterHelper } from '../../../../helpers'

const ModalSuccessfulPurchase = ({ handleOk, isSuccessfulPurchase, isSucccessPurchaseModalVisible }) => {
	const { user } = useContext(userContext)
	const { saldo } = user

	return (
		<Modal
			className="modal-purchase"
			closeIcon={ null }
			footer={ null }
			header={ null }
			onOk={ handleOk }
			visible={ isSucccessPurchaseModalVisible }
		>
			<div className="modal-purchase-content">
				<div className="modal-purchase-content-inner">
					{
						isSuccessfulPurchase === true &&
							<>
								<h3>Tu compra ha sido un éxito !</h3>
								<div className='icon-circle success'>
									<Icon type="check" />
								</div>
							</>
					}
					<Button type="primary" onClick={ handleOk }>Entendido</Button>
				</div>
			</div>
		</Modal>
	)
}

export default ModalSuccessfulPurchase
