import HeaderComponent from './Header/Header'
import ContentComponent from './Content/Content'
import FooterComponent from './Footer/Footer'
import LogoComponent from './Logo/Logo'
import WrapperComponent from './Wrapper/Wrapper'
import ModalChangePasswordComponent from './ModalChangePassword/ModalChangePassword'

export const Header = HeaderComponent
export const Content = ContentComponent
export const Footer = FooterComponent
export const Logo = LogoComponent
export const Wrapper = WrapperComponent
export const ModalChangePassword = ModalChangePasswordComponent