import apiConfig from '../../../../../../../config/api'
import { apiRequestorHelper } from '../../../../../../../helpers'

export default {
	save: (client) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/user/createUser',
			method: 'post',
			body: client
		})
	},
	upload: (formData) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/uploadClientes',
			method: 'post',
			body: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	}
}
