import './wrapper.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

const Wrapper = ({ children, id }) => {
	ReactGA.initialize('UA-1565568-44')
	ReactGA.pageview(window.location.pathname + window.location.search)

	return (
		<div id={ id || 'default-public-wrapper' } className="wrapper public">
			{ children }
		</div>
	)
}

Wrapper.propTypes = {
	children: PropTypes.object.isRequired,
	id: PropTypes.string.isRequired
}

export default Wrapper
