import './purchasehistory.scss'
import React, { useEffect, useState,useContext } from 'react'
import { Col, Row,Pagination, Spin,Tooltip } from 'antd'
import { PurchaseHistoryItem } from './components'
import { getSolicitudesPurchaseHistoryClientPromise } from './promises'
import {Filters} from './components'
import { activeTabIndexContext } from '../../../../../../contexts'


const PurchaseHistory = ({ type }) => {

	const [purchaseHistory, setPurchaseHistory] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [resultsTotalNum, setResultsTotalNum] = useState(0)
	const [resultsPerPage, setResultsPerPage] = useState(10)
	const [isLoading,setIsLoading] = useState(false)
	const [activeFilters,setActiveFilters] = useState({})
	const { notiFilter,setNotiFilter } = useContext(activeTabIndexContext)

	const getFilters = (filters) => {
		setActiveFilters(filters)
		handleSearchResults(1, filters)
	}

	const restoreFilters = () => {
		setActiveFilters({})
		handleSearchResults(1)
	}


	const handlePaginationOnChange = async (currentPage)=>{
		handleSearchResults(currentPage, activeFilters)
	}

	const handleSearchResults = async (currentPage,filters)=>{
		const fromNum = ((currentPage - 1) * resultsPerPage)
		setIsLoading(true)
		const searchResults = await getSolicitudesPurchaseHistoryClientPromise(fromNum, resultsPerPage, type, filters)
		setIsLoading(false)
		setPurchaseHistory(searchResults.data.records)
		setResultsTotalNum(searchResults.data.total)
		setCurrentPage(currentPage)
	}

	useEffect(() => {
		if(notiFilter !== null && notiFilter !== '' && notiFilter !==undefined && type == 1) {
			getFilters({keyword: notiFilter, optDates: 'ALL'})
			setNotiFilter("")
		}else {
			handlePaginationOnChange(1)
		}
	}, [notiFilter])

	return (
		<>
			<Filters
				onSetFilters={ getFilters }
				onRestoreFilters = {restoreFilters}
			/>
			<div className="purchase-history">
				<div className="items-headers">
					<Row>
						<Col span={ 3 } style={{ textIndent: 15 }}>
							Producto
						</Col>
						<Col span={ 3 }>
							# Orden
						</Col>
						<Col span={ 3 }>
							<Tooltip title = "Fecha de solicitud de malla">
								Fecha
							</Tooltip>
						</Col>
						<Col span={ 3 }>
							Rut
						</Col>
						<Col span={ 6 }>
							{ type === 1 ?
								<>
								Razón Social
								</>
								:
								<>
								Nombre
								</>
							}
						</Col>
						{ type === 1 ?
							<Col span={ 3 }>
								<Tooltip title = "Última fecha de actualización de malla">
										Fecha act.
								</Tooltip>

							</Col>
							:
							<Col span={ 3 }>
								Rut Origen
							</Col>
						}
						<Col span={ 3 }>
							Estado
						</Col>
					</Row>
				</div>
				<div className="items">
					{ isLoading ?
						<Spin className ="spinner" size="large" />
						:
						<div className="items-inner">
							{ purchaseHistory.map((purchaseItem, index) => <PurchaseHistoryItem type={type} data={ purchaseItem } key={ index }/>) }
						</div>
					}
				</div>
				<Pagination className = "pagination" current={ currentPage } total={ resultsTotalNum } onChange={ handlePaginationOnChange } pageSize={ resultsPerPage } size="small" />
			</div>
		</>
	)
}

export default PurchaseHistory
