import apiConfig from '../../../../../../../config/api'
import { apiRequestorHelper } from '../../../../../../../helpers'

const initPayment = (amount) => {
	return apiRequestorHelper({
		url: apiConfig.url + '/payment/initPayment',
		method: 'post',
		body: { amount }
	})
}

export default initPayment
