import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers/'

const getAdminData = {
    getPurchaseHistory: (from, size, filters) => {
        return apiRequestorHelper({
            url: apiConfig.url + '/admin/getPurchaseHistory',
            body: {from, size, filters},
            method: 'post',
        })
    },
    getPaymentHistory: (from, size, filters) => {
        return apiRequestorHelper({
            url: apiConfig.url + '/admin/getPaymentHistory',
            body: {from, size, filters},
            method: 'post',
        })
    },
}
export default getAdminData;
