import ModalSuccessfulPurchaseComponent from './ModalSuccessfulPurchase/ModalSuccessfulPurchase'
import TabsComponent from './Tabs/Tabs'
import TabsContentComponent from './TabsContent/TabsContent'
import TopBarComponent from './TopBar/TopBar'
import RutModalComponent from './RutModal/RutModal'
import RutModalFormComponent from './RutModalForm/RutModalForm'
import ConfirmModalComponent from './ConfirmModal/ConfirmModal'
import FiltersComponent from './Filters/Filters'

export const ModalSuccessfulPurchase = ModalSuccessfulPurchaseComponent
export const Tabs = TabsComponent
export const TabsContent = TabsContentComponent
export const TopBar = TopBarComponent
export const RutModal = RutModalComponent
export const RutModalForm = RutModalFormComponent
export const ConfirmModal = ConfirmModalComponent
export const Filters = FiltersComponent
