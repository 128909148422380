import { notification } from 'antd';
import { returnPayService } from '../services'

const returnPayPromise = (paymentId) => {
    return new Promise(async (resolve, reject) => {
    await returnPayService.getPaymentDetail(paymentId)
        .then(response => resolve(response.data))
        .catch(err => notification.error({
            message: "Se ha producido un error"
        }))
        // reject({ error: true }))
    })
}

export default returnPayPromise;