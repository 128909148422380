import apiConfig from '../../../../../../../config/api'
import { apiRequestorHelper } from '../../../../../../../helpers'

export default {
	getTransacciones: (fromNum, resultsPerPage,filters) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/user/getTransaccionesByUser',
			method: 'post',
			body: {
				from: fromNum,
				size: resultsPerPage,
				filters: filters
			}
		})
	},
}
