import './paymentItem.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Col, Row, Tooltip, Icon } from 'antd'
import purchaseImg from './purchase.png'
import moment from 'moment'
import {numberFormatterHelper} from '../../../../../../../../helpers'
import {withTranslation} from 'react-i18next'
import transactionImg from './transaction.png'

const Item = ({ data, history, t }) => {
	const handleAbonoOrChargeClass = () => {
		switch (true) {
		case (data.amount>0):
			return 'abono'
		case (data.amount<0):
			return 'cargo'
		default:
			return 'black'
		}
	}

	return (
		<div className="payment-item">
			<Row>
				<Col className ="avatar-rut first-col" span={ 3 }>
					<div className="avatar-payment">
						{ data.type === 'PURCHASE' && <img className="purchase-img" src={ purchaseImg } alt="" /> }
						{ data.type === 'PAYMENT' && <img src={ transactionImg } alt="" /> }
						{ data.type === 'GIFT' && <Icon type="gift" width={40} height={40} size="large" style={{fontSize: '16px', textIndent: '3px'}}/> }
					</div>
					{
						/*
							<Tooltip title= {t('messages.aml.pagos.type.' + data.type) + (data.type == "PURCHASE" ? ': '+data.product.name:'')  }>
								<div className="avatar-payment">
									<img src={ glosaImg() } alt={ data.type } />
								</div>
							</Tooltip>
						*/
					}
				</Col>
				<Col span={ 8 } >
					{ data.type === 'PAYMENT' ?
						<span>Abono de fondos</span>
						:
						<span>{ t('messages.aml.transaction.' + data.type) }: { data.product.name } ( { data.cant } unidad{ data.cant > 1 && <>es</> } )</span>
					}
				</Col>
				<Col span={ 4 } >
					{ moment(data.creationDate).format("DD-MM-YYYY") }
				</Col>

				<Col span={ 2 } style={{ textAlign: 'right' }} className = 'orden-col'  >
					{
						data.transactionNro
					}
				</Col>

				<Col span={ 3 } className={ handleAbonoOrChargeClass() + ' amount-col'} >
					{ numberFormatterHelper(data.amount) }
				</Col>
				<Col span={ 3 } className="amount-col">
					{ numberFormatterHelper(data.saldo) }
				</Col>
			</Row>
		</div>
	)
}

Item.propTypes = {
	data: PropTypes.object.isRequired
}

export default withRouter(withTranslation()(Item))
