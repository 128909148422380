import apiConfig from '../config/api'
import { apiRequestorHelper } from '../helpers'

export default {
	read: () => {
		return apiRequestorHelper({
			url: apiConfig.url + '/user/currentUser',
			method: 'post'
		})
	},

	changePwd: (password, newpwd) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/changePwd',
			method: 'post',
			body: {
				password,
				newpwd
			}
		})
	},

	logout: () => {
		return apiRequestorHelper({
			url: apiConfig.url + '/auth/logout',
			method: 'post'
		})
	}
}
