import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

const user = {
	getCurrent: () => {
		return apiRequestorHelper({
			url: apiConfig.url + '/user/currentUser',
			method: 'post'
		})
	}
}

export default user
