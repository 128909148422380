import { Button, Col, Form, Icon, Input, Row, message } from 'antd';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './ServiceForm.scss'
import {sendContactFormPromise} from './promises'

const ServiceForm = ({form}) => {

	const { typeOfService } = useParams();
	const {getFieldDecorator, validateFields} = form;
	const [nombre, setNombre] = useState(null);
	const [correo, setCorreo] = useState(null);
	const [nombreEmpresa, setNombreEmpresa] = useState(null);
	const [telefono, setTelefono] = useState(null);
	const [isFormReady, setIsFormReady] = useState(false);

	const cardsObj = [
		{
			id: "1",
			title: "AMLupdate 2.0",
			description: "Full Circular N°49 y N°59 de la UAF",
			addonsList: [
				"Consultas individuales y masivas",
				"Consulta a múltiples bases",
				"Certificados de Debida Diligencia",
				"Certificado de procesos masivos",
				"Registro y monitoreo permanente",
				"Niveles de riesgo diferenciados"
			]
		},
		{
			id: "2",
			title: "UBOfinder",
			description: "Full Circular  N°57 de la UAF",
			addonsList: [
				"Registro de beneficiarios finales",
				"Mallas de propiedad",
				"Participación en sociedades",
			]
		},
		{
			id: "3",
			title: "Conflicto de Interés",
			description: "Investigación y Administración",
			addonsList: [
				"Administración declaraciones",
				"Investigación activa",
				"Auditoría",
				"Partes relacionadas",
			]
		},
		{
			id: "4",
			title: "Monitoreo Transaccional",
			description: "Transacciones y Operaciones Comerciales",
			addonsList: [
				"SWIFT",
				"Transacciones masivas",
				"Alertas Estadísticas",
			]
		}
	];

    const filteredCard = cardsObj.find(item => item.id === typeOfService);

	const handleSendFormData = () => {
		validateFields(['nombre', 'correo', 'telefono', 'nombreEmpresa']).then(() => {
			sendContactFormPromise(nombre, correo, telefono, nombreEmpresa, typeOfService).then((response) => {
				if (response) {
					message.success(response);
					setIsFormReady(true);
				}else{
					message.error("Ha ocurrido un error al ingresar la solicitud")
				}
				
			})
		})
	}

	// const [filteredCard, setFilteredCard] = useState(cardsObj.find(item => item.id === typeOfService));

    return (
        <div className='services-form'>
            <Row>
				<Col span={24}>
                	<div className='service-form-page-title'>SOLICITUD DE UNA DEMO</div>
				</Col>
            </Row>
            <Row type='flex'>
                <div className='service-form-content'>
				{ isFormReady ?
					<div style={{textAlign:'center'}}>
						Se envió el formulario correctamente, pronto le contactaremos.
					</div>

					:
					<>
					<Row type='flex' justify='center' gutter={[0, 50]}>
						<Col xs={{span: 24}} xl={{span: 12, offset: 12}}>
							<div className='service-form-content-title'>AGENDA TU DEMO EN VIVO</div>
						</Col>
					</Row>
					<Row type='flex' align='middle' gutter={[40, 40]}>
						<Col xs={24} md={12}>
							<div className='service-tier-card'>
								<div className='title-card-services'>
									{filteredCard.title}
								</div>
								<div className='description-card-services'>
									{filteredCard.description}
								</div>
								{
									filteredCard.addonsList.map(item =>
										<div>
											<Row className='addon-item'>
												<Col span={3} offset={1} push={1}>
													<Icon type="check-circle" theme="twoTone" twoToneColor="#FD8204" style={{fontSize: 18}}/>
												</Col>
												<Col xs={20} md={14}>
													<div >
														{item}
													</div>
												</Col>
											</Row>
										</div>
									)
								}
								
							</div>
						</Col>
						<Col xs={24} md={12}>
							<div className='service-form-element-wrapper'>
								
								<Form labelAlign='left'>
									<Form.Item  label= "Nombre">
										{getFieldDecorator("nombre", {
											initialValue: nombre,
											rules: [
												{
													required: true,
													message: "Por favor ingrese nombre.",
												},
											]
										})(
											<Input
												placeholder="Ingrese Nombre"
												onChange = {({target})=>(setNombre(target.value))}
											/>
										)}
									</Form.Item>
									<Form.Item  label= "Correo Corporativo">
										{getFieldDecorator("correo", {
											initialValue: correo,
											rules: [
												{
													required: true,
													message: "Por favor ingrese correo.",
												},
												{
													type: 'email',
													message: "Correo no válido."
												}
											]
										})(
											<Input
												placeholder="Ingrese correo"
												onChange = {({target})=>(setCorreo(target.value))}
											/>
										)}
									</Form.Item>
									<Form.Item  label= "Teléfono">
										{getFieldDecorator("telefono", {
											initialValue: telefono,
											rules: [
												{
													required: true,
													message: "Por favor ingrese nro. de teléfono.",
												},
											]
										})(
											<Input
												placeholder="Ingrese teléfono"
												onChange = {({target})=>(setTelefono(target.value))}
											/>
										)}
									</Form.Item>
									<Form.Item  label= "Empresa">
										{getFieldDecorator("nombreEmpresa", {
											initialValue: nombreEmpresa,
											rules: [
												{
													required: true,
													message: "Por favor ingrese nombre de empresa.",
												},
											]
										})(
											<Input
												placeholder="Ingrese Nombre de empresa"
												onChange = {({target})=>(setNombreEmpresa(target.value))}
											/>
										)}
									</Form.Item>
								</Form>
							</div>
						</Col>
					</Row>
					</>
				}
				
				{/* <Row> */}
				{!isFormReady ?
				<Col xs={{span:10, offset:7}} md={{span:4, offset:16}}>
					<Button type='primary' onClick={() => handleSendFormData()} style={{width: '100%'}}>
						Enviar
					</Button>
				</Col>
				:
					<Link to='/'>
						<Col span={2} offset={10}>
						<Button>
							Volver al inicio 
						</Button>
						</Col>
					</Link>
				}
				{/* </Row> */}
                </div>
            </Row>
        </div>
    );
};

export default (Form.create()(ServiceForm))