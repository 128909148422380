import React from 'react'
import { HomeTitle, MainDiv, Payment, Products, Testimonials, Services } from './components'

const Landing = () => (
	<div className="landing">
		<HomeTitle />
		<MainDiv />
		<Products />
		<Services/>
		{/* <Testimonials />
		<Payment /> */}
	</div>
)

export default Landing
