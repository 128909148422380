import './personalinfo.scss'
import React, {useState, useContext,useEffect} from 'react'
import {Form,Row,Col,Spin,Input,Icon,Button,notification} from 'antd'
import { userContext } from '../../../../../../contexts'
import { saveUpdateClientPromise } from './promises'
import { validateRutHelper } from '../../../../../../helpers'
import { validateCompanyRutHelper } from '../../../../../../helpers'
import { withTranslation } from 'react-i18next'
import { Switch } from 'react-router-dom';


const PersonalInfo = ({form,t}) => {

	const { getFieldDecorator,getFieldsError,getFieldValue,setFieldValue,validateFields } = form
	const [isLoading, setIsLoading] = useState(false)
	const { user, refreshUserHandler } = useContext(userContext)
	const [isValid, setIsValid] = useState(false)

	const [rut,setRut] = useState(user.rut)
	const [name,setName] = useState(user.name)
	const [apPaterno,setApPaterno] = useState(user.apPaterno)
	const [apMaterno,setApMaterno] = useState(user.apMaterno)
	const [email,setEmail] = useState(user.email)
	const [telefono,setTelefono] = useState(user.telefono)
	const [celular,setCelular] = useState(user.celular)

	const [companyRut,setCompanyRut] = useState((user.company !=null) ? user.company.rut: '' )
	const [companyRazonSocial,setCompanyRazonSocial] = useState((user.company !=null) ? user.company.razonSocial: '')
	const [companyDisabled,setCompanyDisabled] = useState((user.company != null) ? user.company.validated:false)

	const [passwordCurrent,setPasswordCurrent] = useState('')
	const [passwordNew,setPasswordNew] = useState('')
	const [passwordNewConfirm,setPasswordNewConfirm] = useState('')

	const [razonSocialRequired,setRazonSocialRequired] = useState(false)

	useEffect(()=>{
		validateFields(["apMaterno","telefono"])
	},[])


	const fillData = ()=>{
		validateFields(["apMaterno"])
		let userObj
		if (!companyDisabled && companyRut !== '' && companyRut !== null && companyRazonSocial !== null && companyRazonSocial !== '') {
			userObj = {
				user: {
					rut,
					name,
					apPaterno,
					apMaterno,
					email,
					telefono,
					celular,
					company: {
						rut: companyRut,
						razonSocial: companyRazonSocial
					}
				}
			}
		} else {
			userObj = {
				user: {
					rut,
					name,
					apPaterno,
					apMaterno,
					email,
					telefono,
					celular,
				}
			}
		}

		const password = passwordCurrent
		const newpwd = passwordNew

		if (passwordNew != ''){
			userObj = {...userObj, password,newpwd}
		}

		saveUpdateClientPromise(userObj).then(async (response)=>{
			if (response.data.status == 'OK') {
				notification['success']({
					message: t('messages.aml.notifications.succesfulOperation'),
					description: t('messages.aml.informationSuccesfullyUpdated')
				})
				await refreshUserHandler()
				if(!companyDisabled && userObj.user.company !== undefined && userObj.user.company !== null) {
					setCompanyDisabled(true)
				}
			}else {
				switch (response.data.msg) {
					case "INVALID_PASSWORD":
						notification['error']({
							message: t('messages.aml.errors.badPassword'),
							description: t('messages.aml.wrongCurrentPasswordDescription')
						})
						break;
					default:
						notification['error']({
							message: t('messages.aml.anErrorOcurred'),
							description: t('messages.aml.errors.passwordErrorDescription')
						})
						break;
				}
			}
		})
	}

	const handleOnChangeName = ({target})=>{
		setName(target.value)
	}
	const handleOnChangeApPaterno = ({target})=>{
		setApPaterno(target.value)
	}
	const handleOnChangeApMaterno = ({target})=>{
		setApMaterno(target.value)
	}
	const handleOnChangeEmail = ({target})=>{
		setEmail(target.value)
	}
	const handleOnChangeTelefono = ({target})=>{
		setTelefono(target.value)
	}
	const handleOnChangeCelular = ({target})=>{
		setCelular(target.value)
	}
	const handleOnChangeCompanyRut = ({target})=>{
		setCompanyRut(target.value)
	}
	const handleOnChangeCompanyRazonSocial = ({target})=>{
		setCompanyRazonSocial(target.value)
	}

	const handleOnChangePasswordCurrent = ({target}) => {
		setPasswordCurrent(target.value)
	}

	const handleOnChangePasswordNew = ({target}) => {
		setPasswordNew(target.value)
	}

	const handleOnChangePasswordNewConfirm = ({target}) => {
		setPasswordNewConfirm(target.value)
	}



	const checkNewConfirmPass = (rule,value,cb) => {
		const y = getFieldValue("password-new")
		if (value !== '' && value !== undefined) {
			if (value != getFieldValue("password-new")){
				cb("Passwords deben coincidir")
			}
		}else if(y !== '' && y !== undefined){
			cb("Repetir nueva clave requerida")
		}
	}

	const checkNewPass = (rule,value,cb) => {
		if (value!=="" && value !== undefined ) {
			cb()
			validateFields(['password-new-repeat'])
		}else {
			const y = getFieldValue("password-current")
			if (y!=="" && y !== undefined){
				cb('Nueva clave requerida')
			}else {
				validateFields(['password-current'])
			}
		}
	}

	const checkCurrentPass = (rule,value,cb) => {
		if (value==="" || value === undefined ) {
			const y = getFieldValue("password-new")
			if (y!=="" && y !== undefined){
				cb('Clave actual requerida')
			}else {
				validateFields(['password-new'])
			}
		}else {
			cb()
			validateFields(['password-new'])
		}
	}

	const checkRutCompany = (rule,value,cb) => {
		if (value !== '' && value !== undefined ) {
			if (!validateCompanyRutHelper(value)) {
				cb('RUT de empresa no válido')
			}else{
				validateFields(['companyRazonSocial'])
			}
		}else {
			const y = getFieldValue("companyRazonSocial")
			if (y !=="" && y !== undefined) {
				cb('RUT es requerido')
			}else {
				validateFields(['companyRazonSocial'])
			}
		}
	}

	const checkRazonSocial = (rule,value,cb) => {
		if (value === '' || value === undefined) {
			const y = getFieldValue("companyRut")
			if (y !== "" && y !== undefined){
				cb("Razon social es requerida")
			}else {
				validateFields(['companyRut'])
			}
		}else {
			validateFields(['companyRut'])
		}
	}

	const handlerBlurCompanyRut = ({target}) => {
		setIsValid(validateCompanyRutHelper(target.value))
	}

	function hasErrors(fieldsError) {
		return Object.keys(fieldsError).some(field => fieldsError[field])
	}

	const missingPasswords = () => {
		return ((passwordCurrent != '')||(passwordNew != '')||(passwordNewConfirm != ''))&&((passwordCurrent == '')||(passwordNew == '')||(passwordNewConfirm == ''))
	}

	const missingCompany = () => {
		return ((companyRut != '')||(companyRazonSocial != ''))&&((companyRut == '')||(companyRazonSocial == ''))
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 5 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 12 },
		},
	}


	return (

		<div className = "information">

			<div className="personal-info">
				<h2>Información Personal</h2>

				<div className="personal-info-header">
					<Row>
						<Col xs={9}></Col>
						<Col xs={6}>
							<div className="spin-wrapper">
								<Spin
									style={{ fontColor: "#fff" }}
									spinning={isLoading}
									size={"large"}
								/>
							</div>
						</Col>
						<Col xs={9}></Col>
					</Row>
				</div>

				<div className="personal-info-content">

					<Form label = "Información Personal" {...formItemLayout}>
						<Row>
							<Col span={24}>
								<Form.Item label = "RUT">
									{getFieldDecorator("rut", {
										rules: [
											{
												required: true,
												message: "Campo obligatorio"
											}
										],
										initialValue: user.rut
									})(
										<Input
											placeholder="RUT"
											disabled
										/>
									)}
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<Form.Item label = "Nombre">
									{getFieldDecorator("name", {
										rules: [
											{
												required: true,
												message: "Campo obligatorio"
											}
										],
										initialValue: user.name
									})(
										<Input
										  name = "name"
											placeholder="Nombre"
											onChange = {handleOnChangeName}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<Form.Item label = "Apellido Paterno">
									{getFieldDecorator("apPaterno", {
										rules: [
											{
												required: true,
												message: "Campo requerido"
											}
										],
										initialValue: user.apPaterno
									})(
										<Input
											placeholder="Apellido"
											onChange = {handleOnChangeApPaterno}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<Form.Item label = "Apellido Materno">
									{getFieldDecorator("apMaterno", {
										rules: [
											{
												required: true,
												message: "Campo requerido"
											}
										],
										initialValue: user.apMaterno
									})(
										<Input
											placeholder="Apellido Materno"
											onChange = {handleOnChangeApMaterno}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<Form.Item label = "Email">
									{getFieldDecorator("email", {
										rules: [
											{
												required: true,
												message: 'Campo requerido'
											},
											{
												type: 'email',
												message: 'The input is not valid E-mail!'
											}
										],
										initialValue: user.email
									})(
										<Input
											placeholder="email"
											onChange = {handleOnChangeEmail}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<Form.Item label = "Teléfono">
									{getFieldDecorator("telefono", {
										rules: [
											{
												required: true,
												message: "Campo requerido"
											},
											{
												min: 9,
												message: "se requieren al menos 9 dígitos"
											},
										],
										initialValue: user.telefono
									})(
										<Input
											placeholder="Telefono"
											onChange = {handleOnChangeTelefono}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>

						<Row>
							<Col span={24}>
								<Form.Item label = "Celular">
									{getFieldDecorator("celular", {
										rules: [
											{
												min: 9,
												message: "se requieren al menos 9 dígitos"
											},
										],
										initialValue: user.celular
									})(
										<Input
											placeholder="Celular"
											onChange = {handleOnChangeCelular}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>

						<h2>Información de la Empresa</h2>

						<p style = {{textAlign: "justify",paddingLeft: '185px',paddingRight: '260px'}}>Estimado usuario, le informamos que sólo podrá asociar un RUT de Empresa.
							Por tal motivo, cuando ingrese los datos solicitados, estos quedarán como no modificables a futuro 
							</p>

						<div className="company-info-header">
							<Row>
								<Col xs={9}></Col>
								<Col xs={6}>
									<div className="spin-wrapper">
										<Spin
											style={{ fontColor: "#fff" }}
											spinning={isLoading}
											size={"large"}
										/>
									</div>
								</Col>
								<Col xs={9}></Col>
							</Row>
						</div>

						<Row className = "company-rut-row">
							<Form.Item label = "RUT">
								{getFieldDecorator("companyRut", {
									rules: [
										{validator: checkRutCompany}
									],
									initialValue: companyRut
								})(
									<Input
										disabled = {companyDisabled}
										placeholder="Rut de la empresa"
										onChange = {handleOnChangeCompanyRut}
									/>
								)}
							</Form.Item>
						</Row>

						<Row>
							<Col span={24}>
								<Form.Item label = "Razón Social">
									{getFieldDecorator("companyRazonSocial", {
										rules: [
											{validator: checkRazonSocial}
										],
										initialValue: companyRazonSocial
									})(
										<Input

											disabled = {companyDisabled}
											placeholder="Nombre de la empresa"
											onChange = {handleOnChangeCompanyRazonSocial}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>

						<div className = "password-change">
							<h2>Cambio de Contraseña</h2>
							<Row>
								<Col span = {24}>

									<Form.Item
										label = "Password actual"
									>
										{ getFieldDecorator('password-current', {
											rules: [
												{
													pattern: "^[a-zA-Z0-9]{6,20}$",
													message: t('messages.aml.newPasswordMustContain')
												},
												{
													validator: checkCurrentPass
												}
											],
										})(
											<Input
												prefix={ <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} /> }
												type="password"
												placeholder={ t('messages.aml.currentPassword') }
												onChange={ handleOnChangePasswordCurrent }
											/>
										)}
									</Form.Item>

								</Col>
							</Row>

							<Form.Item label = "Nueva contraseña">
								{ getFieldDecorator('password-new', {
									rules: [
										{
											pattern: "^[a-zA-Z0-9]{6,20}$",
											message: t('messages.aml.newPasswordMustContain')
										},
										{
											validator: checkNewPass
										}
									],
								})(
									<Input
										prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
										type="password"
										placeholder={ t('messages.aml.newPassword') }
										onChange={ handleOnChangePasswordNew }
									/>,
								)}
							</Form.Item>
							<Form.Item label = "Repita nueva contraseña">
								{ getFieldDecorator('password-new-repeat', {
									rules: [
										{
											pattern: "^[a-zA-Z0-9]{6,20}$",
											message: t('messages.aml.newPasswordMustContain')
										},
										{
											validator: checkNewConfirmPass
										}
									],
								})(
									<Input
										prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
										type="password"
										placeholder={ t('messages.aml.newPasswordConfirm') }
										onChange={ handleOnChangePasswordNewConfirm }
									/>
								)}
							</Form.Item>
						</div>

						<Row>
							<Form.Item
								wrapperCol={{
									sm: { offset: 5 },
								}}
							>
								<Button type="primary" htmlType="submit" onClick = {fillData} disabled={hasErrors(getFieldsError())||missingPasswords()||missingCompany()} >
									Enviar
								</Button>
							</Form.Item>
						</Row>

					</Form>

				</div>       {/* personal info content   */}
			</div>         {/* personal info content   */}

		</div>

	)
}

export default withTranslation()(Form.create()(PersonalInfo))
