import React, {useState} from "react"
import {Modal,Input, Form} from 'antd'
import PropTypes from 'prop-types'
import validateRut from '../../../../helpers/validateRut'

const RutModalForm = ({title,visible, setVisible,ModalText, setModalText,form}) => {
	
	const {getFieldDecorator} = form
	const [confirmLoading,setConfirmLoading] = useState(false)
	const [rut,setRut] = useState('')

	const handleOk = () => {
		if (validateRut(rut)) {
			setModalText('The modal will be closed after two seconds')
			setConfirmLoading(true) 
			setTimeout(() => {
				setVisible(false)
				setConfirmLoading(false)
				setModalText('')
				alert(`enviado rut ${rut}`)
			}, 2000)
		}	else {
			setModalText('Rut Nok')
		}
	}

	const handleCancel = () => {
		console.log('Clicked cancel button')
		setVisible(false)
	}

	return (
		<div className="rutModal">
			<Modal
				title={title}
				visible={visible}
				onOk={handleOk}
				confirmLoading={confirmLoading}
				onCancel={handleCancel}
			>

				<Form.Item>
					<p>{ModalText}</p>
					{
						getFieldDecorator('rut',{
							rules: [{
								required: true,
								message: 'rut es requerido'
							}],
						}) (
							<Input 
								placeholder="Introduzca su rut sin puntos y con guión" 
								onChange={({ target }) => setRut(target.value)} 
							/>
						) 
					}
				</Form.Item>

			</Modal>
		</div>
	)
}

RutModalForm.propTypes = {
	title: PropTypes.string.isRequired,
	visible: PropTypes.bool.isRequired,
	setVisible: PropTypes.func.isRequired,
	ModalText: PropTypes.string.isRequired,
	setModalText: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	
}

export default Form.create()(RutModalForm)
