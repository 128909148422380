import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers/'

const payRequestService = {
    startPay: (requestJson) => {
        return apiRequestorHelper({
            url: apiConfig.url + '/guest/incomeRequestReport',
            body: requestJson,
            method: 'post',
        })
    },
}
export default payRequestService;
