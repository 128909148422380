import { Col, Icon, Row } from 'antd';
import React from 'react';
import HandShakeImg from './iconopago.png'
import FlowLogo from './logo-flow.svg'
import './PayingStep.scss'
import redCompraImg from './redcompra.png'
import visaImg from './visa.png'
import masterCardImg from './mastercard.png'
import maestroImg from './maestro.png'
import transBankImg from './transbank.png'

const PayingStep = ({payState}) => {
    return (
        <div className='paying-step-component'>
            <Row gutter={[0, 20]}>
                <Col span={24}>
                    <div className='buy-page-step-title'>
                         <h3>{ payState === 'PAID' ? "Finalización de Compra" : "Operación Rechazada"}</h3>
                    </div>
                </Col>
                <Col span={24}>
                    <div className='image-handshake-pay-step'>
                    { payState === 'PAID' ? 
                        <img src={HandShakeImg} width="20%"/>
                        :
                        <Icon type="stop" style={{fontWeight:800, fontSize: 100, color: "red"}} theme="outlined" twoToneColor="#901a31"/>
                    }
                    </div>
                </Col>
                <Col span={24}>
                    <div style={{textAlign:'center', fontWeight:700, color:"gray"}}>{ payState === 'PAID' ? "Su solicitud ya fue pagada!" : "Su solicitud fue rechazada, vuelta a intentarlo."}</div>
                </Col>
                <Col span={24}>
                    <div style={{textAlign:'center', color:"gray"}}>{ payState === 'PAID' ? "Luego de generado el reporte, se enviará al correo ingresado." : ""}</div>
                </Col>
                <Col span={24}>
                    <div className='image-logos-pay-step'>
                        <ul>
                            <li><img src={ redCompraImg } alt="" /></li>
                            <li><img src={ visaImg } alt="" /></li>
                            <li><img src={ masterCardImg } alt="" /></li>
                            <li><img src={ transBankImg } alt="" /></li>
                            <li><img src={ maestroImg } alt="" /></li>
                            <li><img src={ FlowLogo } alt="" /></li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default PayingStep;