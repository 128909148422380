import React from "react"
import './homeTitle.scss'
import { Carousel } from 'antd';
import img1 from './img/banner1.gif'
import img2 from './img/banner2.gif'
import { Link } from "react-router-dom";

const HomeTitle = () => {


	const handleImg2 = (duration) => {
		const scrollStep = window.scrollY / (duration / 15)
		const scrollInterval = setInterval(function(){
		if ( window.scrollY !== 0 ) {
			window.scrollBy( 0, scrollStep )
		} else clearInterval(scrollInterval)
	},15)
	}

	const items = [
		<img src={img1} role="presentation" />,
		
		<img src={img2} role="presentation" style={{cursor: 'pointer'}} onClick={() => {
			handleImg2(150)
		}}/>
		,

	]
	return (
		<div>
			<Carousel autoplay >
				{items.map((e) => e)}
			</Carousel>
		 </div>
		
	)
}

export default HomeTitle
