import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Home, PaymentHistory, PersonalInfo, PurchaseHistoryTab, Wallet } from './components'
import { userContext } from '../../../../contexts'
import { numberFormatterHelper } from '../../../../helpers'
import './TabsContent.scss'

const TabsContent = ({ activeTabIndex }) => {
	const { user } = useContext(userContext)

	return (
		<div className="tabs-content">
			<div className="tabs-content-inner">
				{ activeTabIndex === 0 && <Home /> }
				{ activeTabIndex === 1 && <PurchaseHistoryTab /> }
				{ activeTabIndex === 2 && <PaymentHistory /> }
				{ activeTabIndex === 3 && <PersonalInfo /> }
				{ activeTabIndex === 4 && <Wallet currentAmount={ numberFormatterHelper(user.saldo) } /> }
			</div>
		</div>
	)
}



TabsContent.propTypes = {
	activeTabIndex: PropTypes.number.isRequired
}

export default TabsContent
