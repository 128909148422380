import HomeComponent from './Home/Home'
import PaymentHistoryComponent from './PaymentHistory/PaymentHistory'
import PersonalInfoComponent from './PersonalInfo/PersonalInfo'
import PurchaseHistoryComponent from './PurchaseHistory/PurchaseHistory'
import PurchaseHistoryTabComponent from './PurchaseHistory/PurchaseHistoryTab'
import WalletComponent from './Wallet/Wallet'

export const Home = HomeComponent
export const PaymentHistory = PaymentHistoryComponent
export const PersonalInfo = PersonalInfoComponent
export const PurchaseHistory = PurchaseHistoryComponent
export const PurchaseHistoryTab = PurchaseHistoryTabComponent
export const Wallet = WalletComponent
