import React, {useContext, useState} from "react"
import { withRouter } from "react-router-dom"
import { Form, Input, Button, Icon, notification } from "antd"
import { userContext } from "../../../../../../contexts"
import "./rightForm.scss"
import PropTypes from 'prop-types'
import { saveRegisterClientPromise } from './promises/index'
import { getAuthTokenPromise, getUserPromise } from "../../../../../Login/promises"
import { authTokenSessionStorageSaverHelper } from "../../../../../../helpers"
import { useTranslation } from 'react-i18next'
import meshImg from './mesh.png'



const RightForm = ({form, history}) => {
	const { loginHandler } = useContext(userContext)

	const { getFieldDecorator, getFieldValue,setFieldValue,validateFields } = form
	const { t } = useTranslation()

	const [rut,setRut] = useState('')
	const [name,setName] = useState('')
	const [apPaterno,setApPaterno] = useState('')
	const [email,setEmail] = useState('')
	const [password,setPassword] = useState('')
	const [passwordRepeat,setPasswordRepeat] = useState('')
	const [isLoading,setIsLoading] = useState(false)


	const handlerLogin = async(username, password) => {
		const token = await getAuthTokenPromise(username, password)

		if (!token.error) {
			await authTokenSessionStorageSaverHelper(token)

			const user = await getUserPromise()

			if (!user.error) {
				await loginHandler(user)
				history.push("/dashboard")
			}
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		form.validateFields((err, values) => {
			if (err === null) {
				const client = {
					rut,
					name,
					apPaterno,
					email,
					password
				}

				saveRegisterClientPromise(client)
					.then(response => {
						if (response.data.status == 'OK') {
							notification.success({
								message: t('messages.aml.successfulOperation'),
								description: t('messages.aml.createUserOk')
							})
							form.resetFields()
							handlerLogin(rut, password)
						}else{
							switch (response.data.msg) {
							case "USER_EXIST":
								notification.error({
									message: t('messages.aml.anErrorOcurred'),
									description: t('messages.aml.userAlreadyRegistered')
								})
								break
							default:
								notification['error']({
									message: t('messages.aml.anErrorOcurred'),
									description: t('messages.aml.registrationErrorDescription')
								})
								break
							}
						}
					})
					.catch(error => {
						console.log(error)
					})
			}
		})
	}

	const checkPassword = (rule,value,cb) => {
		if (value!=="" && value !== undefined ) {
			cb()
			validateFields(['password-repeat'])
		}else {
			cb()
		}
	}

	const checkConfirmPassword = (rule,value,cb) => {
		const y = getFieldValue("password")
		if (value !== '' && value !== undefined) {
			if (value != y){
				cb(t('messages.aml.passwordsMustMatch'))
			}else {
				cb()
			}
		}else {
			cb()
		}
	}

	return (
		<div id="register-form" className="rightForm">
			<Form id = "registration-form" onSubmit={handleSubmit} className="login-form" autocomplete="off">
				<div className="free-stuff-block">
					<h1>Lleva tu propio registro de reportes</h1>
				</div>
				<Form.Item>
					{getFieldDecorator("rut-field", {
						rules: [{ required: true, message: t('messages.aml.pleaseEnterYourRut') }]
					})(
						<Input
							placeholder="RUT"
							autocomplete="off"
							onChange = {({target})=>(setRut(target.value))}
						/>
					)}
				</Form.Item>

				<Form.Item>
					{getFieldDecorator("name", {
						rules: [{ required: true, message: t('messages.aml.pleaseEnterYourName') }]
					})(
						<Input
							placeholder="Nombre"
							autocomplete="off"
							onChange = {({target})=>(setName(target.value))}
						/>
					)}
				</Form.Item>

				<Form.Item>
					{getFieldDecorator("apPaterno", {
						rules: [{ required: true, message: t('messages.aml.pleaseEnterYourLasName') }]
					})(
						<Input
							placeholder="Apellido"
							autocomplete="off"
							onChange = {({target})=>(setApPaterno(target.value))}
						/>
					)}
				</Form.Item>

				<Form.Item>
					{getFieldDecorator("email", {
						rules: [
							{ required: true, message: t('messages.aml.pleaseEnterYourEmail') },
							{ type: 'email', message: t('messages.aml.emailInvalidFormat') }
						]
					})(
						<Input
							type="email"
							placeholder="Email"
							autocomplete="off"
							onChange = {({target})=>(setEmail(target.value))}
						/>
					)}
				</Form.Item>

				<Form.Item>
					{ getFieldDecorator('password', {
						rules: [
							{ required: true, message: t('messages.aml.enterPassword') },
							{
								pattern: "^[a-zA-Z0-9]{6,20}$",
								message: t('messages.aml.newPasswordMustContain')
							},
							{
								validator: checkPassword
							}
						],
					})(
						<Input
							prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
							type="password"
							placeholder={ t('messages.aml.newPassword') }
							autocomplete="off"
							onChange = {({target})=>(setPassword(target.value))}
						/>,
					)}
				</Form.Item>
				<Form.Item>
					{ getFieldDecorator('password-repeat', {
						rules: [
							{ required: true, message: t('messages.aml.enterPassword') },
							{
								pattern: "^[a-zA-Z0-9]{6,20}$",
								message: t('messages.aml.newPasswordMustContain')
							},
							{
								validator: checkConfirmPassword
							}
						],
					})(
						<Input
							prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
							type="password"
							placeholder = {t('messages.aml.confirmPassword')}
							autocomplete="off"
							onChange = {({target})=>(setPasswordRepeat(target.value))}
						/>
					)}
				</Form.Item>

				<Form.Item>
					<Button
						icon="check"
						type="primary"
						htmlType="submit"
						className="register-form-button"
					>
            			CREAR CUENTA
					</Button>
				</Form.Item>


			</Form>
			<div className="white-overlay" />
		</div>
	)
}


RightForm.propTypes = {
	form: PropTypes.object.isRequired
}


export default withRouter(Form.create()(RightForm))
