import animateLogout from './animateLogout'
import getAuthToken from './getAuthToken'
import getCurrentUser from './getCurrentUser'
import getProductsPrices from './getProductsPrices'
import logout from './logout'
import changePassword from './changePassword'

export const animateLogoutPromise = animateLogout
export const getAuthTokenPromise = getAuthToken
export const getCurrentUserPromise = getCurrentUser
export const getProductsPricesPromise = getProductsPrices
export const logoutPromise = logout
export const changePasswordPromise = changePassword