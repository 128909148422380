import apiConfig from '../config/api'
import { apiRequestorHelper } from '../helpers'

export default () => {
	return apiRequestorHelper({
		url: apiConfig.url + '/product/getProducts',
		method: 'post',
		body: {}
	})
}
