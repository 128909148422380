import i18n from 'i18next'
import { initReactI18next } from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector'
import langEsCl from '../locales/esCl.json'
import langEnCl from '../locales/enCl.json'


i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			esCHI: {
				translations: langEsCl
			},
			enCHI: {
				translations: langEnCl
			}
		},

		detection: {
			//order: ['navigator'],
			lookupQuerystring: 'lang'
		},

		fallbackLng: "esCHI",
		debug: true,

		ns: ["translations"],

		defaultNS: "translations",

		keySeparator: false, // we use content as keys

		interpolation: {
			formatSeparator: ","
		},

		react: {
			wait: true
		}
	})

export default i18n
