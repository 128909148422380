const api = {}

if ( process.env.REACT_APP_ENV === 'prod') {
	api.url = 'https://api-tienda.gesintel.cl/api'
	api.paymentUrl = 'https://gw.pagofacil.cl/initTransaction'
}else if(process.env.REACT_APP_ENV === 'qa') {
	api.url = 'https://api-tienda-qa.gesintel.cl/api'
	api.paymentUrl = 'https://gw-dev.pagofacil.cl/initTransaction'
}else {
	api.url = 'https://api-tienda-qa.gesintel.cl/api'
	api.paymentUrl = 'https://gw-dev.pagofacil.cl/initTransaction'
	//api.url = 'http://localhost:5000/api'
}

export default api
