import { Button, Col, Form, Icon, Input, Row, Steps, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './Buying.scss';
import { validateRutHelper, validateCompanyRutHelper } from '../../helpers';
import { Link, useParams } from 'react-router-dom';
import { userContext } from '../../contexts';
import { PayingStepComp } from './components';
import { payRequestPromise, returnPayPromise } from './promises';


const Buying = ({form}) => {
    const { prod, paymentId } = useParams();
    const { Step } = Steps;
    const {getFieldDecorator, validateFields} = form;
    const [ solicitudes, setSolicitudes ] = useState([])
    const [nombreBoleta, setNombreBoleta] = useState(null);
    const [rutBoleta, setRutBoleta] = useState(null);
    const [correoBoleta, setCorreoBoleta] = useState(null);
    const [current, setCurrent] = useState(0);
    const infoProd = useContext(userContext).productsPrices.data.find(e => e.id === prod);
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    const [mailConfirm, setMailConfirm] = useState(null);
    const [payState, setPayState] = useState(null);

    useEffect(() => {
        if (paymentId){
            returnPayPromise(paymentId).then(response =>{
                if(response){
                    setCurrent(3)
                    setRutBoleta(response.rut)
                    setCorreoBoleta(response.email)
                    setNombreBoleta(response.name)
                    setSolicitudes(response.transaction.solicitudes)
                    setPayState(response.status)
                }
            })
        }else {
            addSolicitud()
        }
    }, []);

    const addSolicitud = () => {
        let sol = [ ...solicitudes ]
        sol.push({ id: parseInt(Math.random()*10000,10) })
        setSolicitudes(sol)
    }

    const TIER_TEXT = {
        1: [
                "Malla de Propiedad con todos los niveles incluyendo el RUT, Nombre y Participación de cada beneficiario final.",
                "Identificación de empresas dueñas de otras empresas y sus participantes.",
                "Información verificada de fuentes públicas.",
                "Certificado de UBO - Beneficiarios Finales."
            ],
        2: [
                "Participación en sociedades de una persona, identificando la Razón Social, RUT y % de participación.",
                "Identificación de la totalidad de empresas en las que participa la persona.",
                "Información verificada de fuentes públicas."
            ],
        3: [
                "Podrá saber si la persona consultada es PEP o está relacionada con un PEP.",
                "Podrá saber si la persona consultada es Funcionario Público de alguna repartición del estado",
                "Búsqueda de personas de interés.",
                "Busqueda en listas internacionales."
            ]
    }

    const initPay = (payInfoObj) => {
        setIsLoadingPayment(true)
        payRequestPromise(payInfoObj).then((response) => {
            window.location.replace(response.url+"?token="+response.token);
        })
    }

    const steps = [
        {
            title: 'Búsqueda',
            icon: <Icon type="search"/>,
        },
        {
            title: 'Identificación',
            icon: <Icon type="solution" />
        },
        {
            title: 'Revisión y confirmación',
            icon: <Icon type={isLoadingPayment === true ? "loading" : current < 2 ? "eye": "check"} />
        },
        {
            title: "Pago y finalización",
            icon: <Icon type="dollar" />
        }
    ];

    const emailConfirm = (rule, value, cb) => {
        if (value !== correoBoleta){
            cb("correo no se repita")
        }
        cb();
    }

    const stepChange = () => {
        if (current === 0 ){
            let fields = []
            let r = solicitudes.map(s => 'rut-' + s.id)
            fields = [...fields, ...r]
            let n = solicitudes.map(s => 'name-' + s.id)
            fields = [...fields, ...n]
            validateFields(fields).then(() => {
                setCurrent(current + 1)
            })
        }else if(current === 1){
            validateFields(['nameBoleta', 'rutBoleta', 'correoBoleta', 'mailConfirm']).then(() => {
                setCurrent(current + 1)
            })
        }else if(current === 2){
            const payInfoObj = {
                productId: prod,
                rut: rutBoleta,
                email: correoBoleta,
                name: nombreBoleta,
                details: solicitudes

            }
            initPay(payInfoObj)
        }
    }

    const validadorRut = (rule, value, cb) => {
        if (value) {
            if(prod === '1') {
                if(!validateCompanyRutHelper(value)) {
                    cb("Rut de empresa no válido");
                }
            }else if(!validateRutHelper(value)) {
                cb("Documento no válido");
            }
        }
        cb();
    };

    const validadorRutTodos = (rule, value, cb) => {
        if (value) {
            if(!validateRutHelper(value)) {
                cb("Documento no válido");
            }
        }
        cb();
    };

    const changeAttrSolicitud = (attr, index, val) => {
        let sol = [ ...solicitudes ]
        sol[index][attr] = val
        setSolicitudes(sol)
    }

    const removeSolicitud = (index) => {
        let sol = [ ...solicitudes ]
        sol.splice(index, 1);
        setSolicitudes(sol)
    }

    return (
        <div className='buying-page'>
            <Row type='flex' align='middle' justify='center'>
                <div className='header-banner-buy-page'>
                    <div className='type-buy-banner-subtitle'>
                        Proceso de Pago
                    </div>
                    <div className='type-buy-banner-name'>
                        COMPRA DE UN REPORTE
                    </div>
                </div>
            </Row>
            <div className='buy-page-steps-wrapper'>
                <Steps current={current} type="navigation">
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} icon={item.icon} />
                    ))}
                </Steps>
                <Form>
                    <Col xs={24} xl={10}>
                        <div className='buy-page-card-wrapper'>
                            <div className='buy-page-product-card'>
                                <div className='buy-page-card-header'>
                                    {infoProd.name}
                                </div>
                                <div className='buy-page-card-body'>
                                    <Col span={24}>
                                        {infoProd.description} <br/><br/>
                                    </Col>
                                    <Row gutter={[0, 20]}>
                                    {
                                        TIER_TEXT[infoProd.id].map((t) => {
                                        return<>
                                                <Col span={3}>
                                                    <Icon type="check-circle" theme="twoTone" twoToneColor="#FD8204" style={{fontSize: 16}} />
                                                </Col>
                                                <Col span={21}>
                                                    <div style={{textAlign: 'left'}}>
                                                        {t}
                                                    </div>
                                                </Col>
                                            </>
                                        })
                                    }
                                    </Row>
                                </div>
                                <div className='buy-page-card-footer'>
                                    Precio por RUT
                                    <div className='buy-page-footer-price'>
                                        ${infoProd.price}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} xl={13} >
                        <div className="buy-page-steps-content">
                            {current === 0 &&
                                <>
                                <Row>
                                    <Col span={24}>
                                        <div className='buy-page-step-title'>
                                            <h3>VALIDACIÓN PARA OBTENER REPORTE</h3>
                                        </div>
                                    </Col>
                                </Row>
                                { solicitudes.map((solicitud, index) =>
                                    <Row style={{paddingBottom: 15}} type="flex">
                                        <Col span={23}>
                                            <Form.Item  label= "Rut a Consultar">
                                                {getFieldDecorator("rut-"+solicitud.id, {
                                                    initialValue: solicitud.rut,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Por favor ingrese RUT a consultar.",
                                                        },
                                                        {
                                                            validator: validadorRut,
                                                            message: prod === '1' ? "RUT de empresa no es válido" : "Ingrese RUT válido."
                                                        }
                                                    ]
                                                })(
                                                    <Input
                                                        placeholder="XX.XXX.XXX-X"
                                                        onChange = {({target}) => changeAttrSolicitud('rut', index, target.value)}
                                                    />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Nombre">
                                                {getFieldDecorator("name-"+solicitud.id, {
                                                    initialValue: solicitud.name,
                                                    rules: [{ required: true, message: "Por favor ingrese Nombre a consultar." }]
                                                })(
                                                    <Input
                                                        placeholder="Ingrese Nombre"
                                                        onChange = {({target}) => changeAttrSolicitud('name', index, target.value)}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={1} className='acciones'>
                                            <div>
                                                <Tooltip title="Agregar solicitud">
                                                    <Button icon='plus' size='small' type="primary" onClick={() => addSolicitud()}/>
                                                </Tooltip>
                                                { index > 0 &&
                                                    <Tooltip title="Quitar solicitud">
                                                        <Button icon='close' size='small' onClick={() => removeSolicitud(index)}/>
                                                    </Tooltip>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                </>
                            }
                            {current === 1 &&
                                <Row>
                                    <Col span={24}>
                                        <div className='buy-page-step-title'>
                                            <h3>IDENTIFICACIÓN PARA ENVÍO DE REPORTE</h3>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item  label= "Tu Nombre o Empresa">
                                            {getFieldDecorator("nameBoleta", {
                                                initialValue: nombreBoleta,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Por favor ingrese nombre o empresa.",
                                                    },
                                                ]
                                            })(
                                                <Input
                                                    placeholder="Nombre o Empresa"
                                                    onChange = {({target})=>(setNombreBoleta(target.value))}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item  label= "RUT">
                                            {getFieldDecorator("rutBoleta", {
                                                initialValue: rutBoleta,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Por favor ingrese RUT para boleta.",
                                                    },
                                                    {
                                                        validator: validadorRutTodos,
                                                        message: "Ingrese RUT válido."
                                                    }
                                                ]
                                            })(
                                                <Input
                                                    placeholder="XX.XXX.XXX-X"
                                                    onChange = {({target})=>(setRutBoleta(target.value))}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Email">
                                            {getFieldDecorator("correoBoleta", {
                                                initialValue: correoBoleta,
                                                rules: [
                                                    {
                                                        required: true, 
                                                        message: "Por favor ingrese un correo." 
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: "Ingrese un correo válido."
                                                    },
                                                ]
                                            })(
                                                <Input
                                                    placeholder="Ingrese email"
                                                    onChange = {({target})=>(setCorreoBoleta(target.value))}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item label="Confirme Email">
                                            {getFieldDecorator("mailConfirm", {
                                                rules: [
                                                    {
                                                        required: true, 
                                                        message: " "
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: "Ingrese un correo válido."
                                                    },
                                                    {
                                                        validator: emailConfirm,
                                                        message: "Lo correos no coinciden."
                                                    }
                                                ]
                                            })(
                                                <Input
                                                    placeholder="Ingrese email"
                                                    autoComplete="off"
                                                    onChange = {({target})=>(setMailConfirm(target.value))}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            {current === 2 &&
                                <Row justify="center" gutter={[0, 10]}>
                                    {isLoadingPayment ?
                                        <div style={{textAlign: "center"}}>
                                            <div className='sending-to-payment-page'>
                                                <p>
                                                    <h4>ENVIANDO A PAGINA DE PAGOS</h4><Icon type="loading"/>
                                                </p>
                                            </div>
                                        </div>
                                    :
                                        <div style={{textAlign: "center"}}>
                                            <Col span={24}>
                                                <div className='buy-page-step-title' >
                                                    <h3>CONFIRMAR DATOS</h3>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <h4>Persona a Consultar</h4>
                                            </Col>
                                            <Col span={12}>
                                                <h4>Tus Datos</h4>
                                            </Col>

                                            <Col span={12}>
                                                { solicitudes.map(solicitud => 
                                                    <>
                                                    <Col span={24}>
                                                        RUT A CONSULTAR: {solicitud.rut ?? "--------"}
                                                    </Col>
                                                    <Col span={24}>
                                                        NOMBRE: {solicitud.name ?? "--------"}
                                                    </Col>
                                                    </>    
                                                )}
                                            </Col>
                                            <Col span={12}>
                                                <Col span={24}>
                                                    TU NOMBRE O EMPRESA: {nombreBoleta ?? "--------"}
                                                </Col>
                                                <Col span={24}>
                                                    RUT: {rutBoleta ?? "--------"}
                                                </Col>
                                                <Col span={24}>
                                                    CORREO: {correoBoleta ?? "--------"}
                                                </Col>
                                            </Col>
                                            
                                            
                                        </div>
                                    }
                                </Row>
                            }
                            {current === 3 &&
                                <PayingStepComp payState={payState}/>
                            }
                        </div>
                        <div className="buy-page-steps-action">
                            {current < steps.length - 1 && (
                                <Button type="primary" onClick={() => stepChange()} disabled={isLoadingPayment}>
                                    {current === 2 ? "Iniciar Pago" : "Siguiente"}
                                </Button>
                            )}
                            {current === steps.length - 1 && payState === 'PAID' ?
                                <Link to="/">
                                    <Button type="primary">
                                        Volver al inicio
                                    </Button>
                                </Link>
                                :
                                 current === 3 &&
                                    <Button type="primary" onClick={() => setCurrent(2)}>
                                        Volver a intentar
                                    </Button>
                            }
                            {current > 0 && current < 3 && (
                                <Button style={{ marginLeft: 8 }} onClick={() => setCurrent(current - 1)}>
                                    Anterior
                                </Button>
                            )}
                        </div>
                    </Col>
                </Form>
                
            </div>
        </div>
    )
};

export default withTranslation()(Form.create()(Buying))