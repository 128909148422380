import apiConfig from '../../../../../../../config/api'
import { apiRequestorHelper } from '../../../../../../../helpers'

export default {
	getSolicitudes: (fromNum, resultsPerPage, type, filters) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/user/getSolicitudesByUser',
			method: 'post',
			body: {
				from: fromNum,
				size: resultsPerPage,
				type,
				filters: filters
			}
		})
	},
	getDetalleSolicitud: (id) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/report/getDetalleSolicitud',
			method: 'post',
			body: {
				id: id,
			}
		})
	},
	purchaseDetails: (amlOrder) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/report/purchaseDetails',
			method: 'post',
			body: amlOrder,
		})
	},
}
