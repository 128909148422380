import apiRequestor from './apiRequestor'
import authTokenRenewer from './authTokenRenewer'
import authTokenSessionStorageSaver from './authTokenSessionStorageSaver'
import authTokenValidator from './authTokenValidator'
import registerFormHighlighter from './registerFormHighlighter'
import scrollToTop from './scrollToTop'
import sessionStorageCleaner from './sessionStorageCleaner'
import validateRut from './validateRut'
import validateCompanyRut from './validateCompanyRut'
import numberFormatter from './numberFormatter'
import camelizer from './camelizer'
import decodeAuthToken from './decodeAuthToken'

export const apiRequestorHelper = apiRequestor
export const authTokenRenewerHelper = authTokenRenewer
export const authTokenSessionStorageSaverHelper = authTokenSessionStorageSaver
export const authTokenValidatorHelper = authTokenValidator
export const registerFormHighlighterHelper = registerFormHighlighter
export const scrollToTopHelper = scrollToTop
export const sessionStorageCleanerHelper = sessionStorageCleaner
export const validateRutHelper = validateRut
export const validateCompanyRutHelper = validateCompanyRut
export const numberFormatterHelper = numberFormatter
export const camelizerHelper = camelizer
export const decodeAuthTokenHelper = decodeAuthToken