import Activate from './Activate/Activate'
import Dashboard from './Dashboard/Dashboard'
import Landing from './Landing/Landing'
import Login from './Login/Login'
import Buying from './Buying/Buying'
import ServiceForm from './ServiceForm/ServiceForm'
import Admin from './Admin/Admin'

export const ActivatePage = Activate
export const DashboardPage = Dashboard
export const LandingPage = Landing
export const LoginPage = Login
export const BuyingPage = Buying
export const ServiceFormPage = ServiceForm;
export const AdminPage = Admin;
