import apiConfig from '../../../config/api'
import { apiRequestorHelper } from '../../../helpers'

export default (productId, request) => {
	return apiRequestorHelper({
		url: apiConfig.url + '/product/newRequest',
		method: 'post',
		body: {
			productId,
			request
		}
	})
}
