import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import './Gallery.scss'

class Gallery extends React.Component {

	onSlideChange(e) {
	}

	onSlideChanged(e) {

	}


	render() {
		const responsive = {
			0: {
				items: 3
			},
			600: {
				items: 4
			},
			1024: {
				items: 5
			}
		}

		return (
			<div className="gallery">
				<AliceCarousel
					duration={400}
					autoPlay={true}
					startIndex = {1}
					fadeOutAnimation={true}
					mouseDragEnabled={false}
					playButtonEnabled={false}
					responsive={responsive}
					autoPlayInterval={2000}
					autoPlayDirection="rtl"
					autoPlayActionDisabled={true}
					onSlideChange={this.onSlideChange}
					onSlideChanged={this.onSlideChanged}
					buttonsDisabled = {true}
					dotsDisabled = {true}
				>
					<div className="yours-custom-class">
						<img src="https://picsum.photos/id/100/100" className="yours-custom-class" />
					</div>
					<div className="yours-custom-class">
						<img src="https://picsum.photos/id/101/100" className="yours-custom-class" />
					</div>
					<div className="yours-custom-class">
						<img src="https://picsum.photos/id/102/100" className="yours-custom-class" />
					</div>
					<div className="yours-custom-class">
						<img src="https://picsum.photos/id/103/100" className="yours-custom-class" />
					</div>
					<div className="yours-custom-class">
						<img src="https://picsum.photos/id/104/100" className="yours-custom-class" />
					</div>
				</AliceCarousel>
			</div>

		)
	}
}

export default Gallery
