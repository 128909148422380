
import './Services.scss'
import { Button, Col, Icon, Row } from 'antd'
import filesImg from './files.png'
import { Link } from 'react-router-dom'

const Services = () => {

	const cardsObj = [
		{
			id: "1",
			title: "AMLupdate 2.0",
			description: "Full Circular N°49 y N°59 de la UAF",
			addonsList: [
				"Consultas individuales y masivas",
				"Consulta a múltiples bases",
				"Certificados de Debida Diligencia",
				"Certificado de procesos masivos",
				"Registro y monitoreo permanente",
				"Niveles de riesgo diferenciados"
			]
		},
		{
			id: "2",
			title: "UBOfinder",
			description: "Full Circular  N°57 de la UAF",
			addonsList: [
				"Registro de beneficiarios finales",
				"Mallas de propiedad",
				"Participación en sociedades",
			]
		},
		{
			id: "3",
			title: "Conflicto de Interés",
			description: "Investigación y Administración",
			addonsList: [
				"Administración declaraciones",
				"Investigación activa",
				"Auditoría",
				"Partes relacionadas",
			]
		},
		{
			id: "4",
			title: "Monitoreo Transaccional",
			description: "Transacciones y Operaciones Comerciales",
			addonsList: [
				"SWIFT",
				"Transacciones masivas",
				"Alertas Estadísticas",
			]
		}
	]

	return (
		<>
			<div className="services">
				<div className="image-wrapper" alt="">
					<img src={ filesImg } alt="" />
				</div>
				<div style={{textAlign:'center'}}>
					<big className="products-block-title">Soluciones Digitales para Compliance.</big>
					<h2 className='products-block-subtitle'>¡Que se adaptan a la medida de tu empresa!</h2>					
				</div>
				<div className='row-cards-wrapper'>
					<Row gutter={[20, 0]} type='flex'>
							{
								cardsObj?.map((item) => {
									return  <Col xs={24} md={12} lg={6} xxl={6}>
												<div className='service-tier-card'>
													<div className='title-card-services'>{item.title}</div>
													<div className='description-card-services'>{item.description}</div>
														{item.addonsList.map((addon) => {
															return <div className='tier-addons-service'>
																<Row>
																	<Col span={3}>
																		<Icon type="check-circle" theme="twoTone" twoToneColor="#FD8204" style={{fontSize: 16}} />
																	</Col>
																	<Col span={21}>
																		{addon}
																	</Col>
																</Row>
															</div>
														})
														}
														
													
												</div>
												<Col span={24}>
													<div className='service-request-btn'>
														<Link to={'/serviceContact/'+item.id}>
															<Button type='primary'>
																DEMO EN VIVO
															</Button>
														</Link>
													</div>
												</Col>
											</Col>
								})
							}
					</Row>
				</div>
				<div className="clearer" />
			</div>
		</>
	)
}

export default Services
