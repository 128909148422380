import './dashboard.scss'
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Col, Icon, Row } from 'antd'
import { userContext,activeTabIndexContext } from '../../contexts'
import { ModalSuccessfulPurchase, Tabs, TabsContent, TopBar } from './components'
import { getNotificationReadPromise } from './promises'
import { useTranslation } from 'react-i18next'
import {getSolicitudesPurchaseHistoryClientPromise} from './components/TabsContent/components/PurchaseHistory/promises'



const Dashboard = ({ history, match }) => {
	const { isLoggedIn, refreshUserHandler, user, handlerSetTabActive } = useContext(userContext)
	const [ activeTabIndexSt, setActiveTabIndexSt ] = useState(0)
	const [isSucccessPurchaseModalVisible, setIsSuccessfulPurchaseModalVisible] = useState(false)
	const [isSuccessfulPurchase, setIsSuccessfulPurchase] = useState(null)
	const [ notiFilter, setNotiFilter ] = useState("")
	const { t } = useTranslation()


	const tabIndexHandler = (tab)=>{
		setActiveTabIndexSt(tab)
	}

	useEffect(() => {
		const { tab } = match.params
		const { status } = match.params

		if (!isLoggedIn) {
			history.push('/login')
		} else {
			if (tab !== undefined) {
				setActiveTabIndexSt(parseInt(tab))

				if (tab === '4' && status === 'completed') {
					setIsSuccessfulPurchaseModalVisible(true)
					setIsSuccessfulPurchase(true)
				}
			}
			handlerSetTabActive(tabIndexHandler)
		}
	}, [])

	const handleClickedTab = (e, index) => {
		e.preventDefault()

		setActiveTabIndexSt(index)
	}

	const handleViewPurchases = async(e, notification) => {
		handleCloseBanner(notification.id)
		setNotiFilter(''+notification.solicitud.orderId)
		handleClickedTab(e, 1)

		//getSolicitudesPurchaseHistoryClientPromise(0,3,1,{keyword: ''+notification.solicitud.orderId, optDates:"ALL"})
	}

	const handleCloseBanner = async id => {
		await getNotificationReadPromise(id)
		await refreshUserHandler()

	}

	const handleOk = () => {
		setIsSuccessfulPurchaseModalVisible(false)
	}

	return (
		<div className="dashboard">
			<TopBar setActiveTabIndex = {setActiveTabIndexSt} />
			{
				user !== null && user !== undefined && user.notifications !== undefined && user.notifications !== null && user.notifications.length > 0 &&
					<div className="notifications-block">
						{
							user.notifications.map((notification, index) =>
								<div className="highlight-banner" key={ Math.random() }>
									{
										notification.type !== 'ACTIVATE' &&
										<Icon type="close" onClick={ () => handleCloseBanner(notification.id) } style={{ cursor: 'pointer' }}/>
									}
									{
										notification.type === 'GIFT' &&
												<div className="text">
													<h3>#{ index + 1 } - Tenemos algo para tí</h3>
													<p>Hemos encontrado <strong>{notification.cant}</strong> beneficiarios finales de tu empresa RUT: <strong>{notification.solicitud.rut}</strong>, <a href="#" onClick={ e => handleViewPurchases(e, notification) } >click aquí para verlas.</a></p>
												</div>
									}
									{
										notification.type === 'REQUEST' &&
												<div className="text">
													<h3>#{ index + 1 } - Está lista tu solicitud</h3>
													{ notification.solicitud.product.id === '1' ?
														<p>Hemos encontrado <strong>{notification.cant}</strong> beneficiarios finales de la empresa RUT: <strong>{notification.solicitud.rut}</strong>, <a href="#" onClick={ e => handleViewPurchases(e, notification) } >click aquí para verlas.</a></p>
														:
														<p>Hemos encontrado <strong>{notification.cant}</strong> sociedad(es) en la que participa la entitdad RUT: <strong>{notification.solicitud.rut}</strong>, <a href="#" onClick={ e => handleViewPurchases(e, notification) } >click aquí para verlas.</a></p>
													}
												</div>
									}
									{
										notification.type === 'ACTIVATE' &&
												<div className="text">
													<h3>#{ index + 1 } - {t('messages.aml.userStillNotActivated')}</h3>
													<p>Le sugerimos completar sus datos en la opción de <a href="#" onClick = {()=>setActiveTabIndexSt(3)}>Información personal</a> para acceder el beneficio </p>
												</div>
									}
								</div>
							)
						}
						{
							user.notifications.length > 1 &&
								<div className="ghost-notifications-group">
									<div className="ghost-notification" />
									{ user.notifications.length > 2 &&
										<div className="ghost-notification" />
									}
								</div>
						}
					</div>
			}
			<Row>
				<Col span={4}>
					<Tabs activeTabIndex={ activeTabIndexSt } clickedTabHandler={ handleClickedTab } />
				</Col>
				<Col span={20}>
					<activeTabIndexContext.Provider value = {{tabIndexHandler, notiFilter, setNotiFilter}}  >
						<TabsContent activeTabIndex={ activeTabIndexSt }/>
					</activeTabIndexContext.Provider>

				</Col>
			</Row>
			<ModalSuccessfulPurchase handleOk={ handleOk } isSuccessfulPurchase={ isSuccessfulPurchase } isSucccessPurchaseModalVisible={ isSucccessPurchaseModalVisible } />
		</div>
	)
}

Dashboard.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.string.isRequired
}

export default withRouter(Dashboard)