import apiConfig from '../../../../config/api'
import {apiRequestorHelper} from '../../../../helpers'

export default {
	save: (token) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/user/activateUser',
			method: 'post',
			body: token
		})
	},
	upload: (formData) => {
		return apiRequestorHelper({
			url: apiConfig.url + '/uploadClientes',
			method: 'post',
			body: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
	}
}
