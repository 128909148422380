import "./login.scss"
import React, { useContext, useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button, Col, Form, Icon, Input, Row, notification, Spin } from "antd"
import { userContext } from "../../contexts"
import { getAuthTokenPromise, getUserPromise } from "./promises"
import { authTokenSessionStorageSaverHelper, decodeAuthTokenHelper } from "../../helpers"
import { resetPasswordPromise } from "./promises"
import logoImg from './logo.png'
import { LocalStorageService } from "../../services"

const Login = ({ form, history }) => {
	const { isLoggedIn, loginHandler, user } = useContext(userContext)
	const { getFieldDecorator } = form
	const [isRestore, setIsRestore] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const { t } = useTranslation()

	useEffect(() => {
		if (isLoggedIn) {
			if(user?.userType === 'ADMIN') history.push("/admin")
			else history.push("/dashboard")
		}
	}, [])

	const handleSubmit = async e => {
		e.preventDefault()

		await form.validateFields(["username", "password"])

		const { username, password } = form.getFieldsValue()

		const token = await getAuthTokenPromise(username, password)

		if (!token.error) {
			await authTokenSessionStorageSaverHelper(token)
			const decodedToken = decodeAuthTokenHelper(token.token);
			LocalStorageService.create("userType",decodedToken.type);

			const user = await getUserPromise()

			if (!user.error) {
				await loginHandler(user)
				if(user.userType === 'ADMIN') history.push("/admin")
				else history.push("/dashboard")
			}
		}
	}

	const handleSwitchToRestore = e => {
		e.preventDefault()
		setIsRestore(true)
	}

	const handleSwitchToLogin = e => {
		e.preventDefault()
		setIsRestore(false)
	}

	const handleRestorePassword = async e => {
		e.preventDefault()

		//await this.validateFields(['username'])

		setIsLoading(true)

		const login = e.target.elements[0].value
		const reset = await resetPasswordPromise(login)

		if (reset.success) {
			notification.success({
				message: t("messages.aml.successfulOperation"),
				description: t("messages.aml.checkYourEmail")
			})

			window.setTimeout(async () => {
				setIsRestore(false)
			}, 4500)
		} else {
			notification.error({
				message: t("messages.aml.anErrorOcurred"),
				description: t("messages.aml.usernameDoesNotExists")
			})
		}
		setIsLoading(false)
	}

	return (
		<div className="login">
			<div className="login-content">
				<Row>
					<Col span={ 8 }>
						<div className="login-box">
							<Form onSubmit={isRestore ? handleRestorePassword : handleSubmit}>
								<Row>
									<Col xs={24}>
										<h2>
											{isRestore
												? t("messages.aml.restorePassword")
												: "Acceso Clientes"}
										</h2>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Form.Item>
											{getFieldDecorator("username", {
												rules: [
													{
														required: true,
														message: "Campo obligatorio"
													}
												]
											})(
												<Input
													placeholder="Rut"
													prefix={
														<Icon
															type="user"
															style={{ color: "rgba(0, 0, 0, .25)" }}
														/>
													}
												/>
											)}
										</Form.Item>
									</Col>
								</Row>

								{!isRestore && (
									<Row>
										<Col span={24}>
											<Form.Item>
												{getFieldDecorator("password", {
													rules: [
														{
															required: true,
															message: "Campo obligatorio"
														}
													]
												})(
													<Input
														placeholder="Clave"
														prefix={
															<Icon
																type="lock"
																style={{ color: "rgba(0, 0, 0, .25)" }}
															/>
														}
														type="password"
													/>
												)}
											</Form.Item>
										</Col>
									</Row>
								)}

								<Row>
									<Col span={24}>
										<Button type="primary" htmlType="submit">
											{ isRestore ? <>Reestablecer contraseña</> : <>Iniciar sesión</> }
										</Button>
									</Col>
								</Row>
							</Form>
						</div>
						{!isRestore && (
							<a
								href="#"
								className="login-link"
								onClick={handleSwitchToRestore}
								style={{ display: "block", textAlign: "center", paddingTop: 15 }}
							>
								{t("messages.aml.forgotYourPassword")}
							</a>
						)}
						{isRestore && (
							<a
								href="#"
								className="login-link"
								onClick={handleSwitchToLogin}
								style={{ display: "block", textAlign: "center", paddingTop: 15 }}
							>
								{t("messages.aml.backToLogin")}
							</a>
						)}
					</Col>
					<Col className="login-aside" span={ 16 } style={{ background: 'rgba(221, 232, 255, .2)', minHeight: 800 }}>
						<img src={ logoImg } alt="" />
						<p>Como cliente de la Tienda Gesintel usted tiene acceso a los siguientes beneficios :</p>
						<div className="login-features">
							<ul>
								<li><Icon type="check" /> Posibilidad de solicitar 1 o más Mallas de Propiedad.</li>
								<li><Icon type="check" /> Posibilidad de solicitar 1 o más Participación en Sociedades.</li>
								<li><Icon type="check" /> Posibilidad de cargar tu monedero para hacer solicitudes rápidamente.</li>
								<li><Icon type="check" /> Malla societaria para el rut de tu empresa, 100% gratuita.</li>
							</ul>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	)
}

Login.propTypes = {
	history: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired
}

export default withRouter(Form.create()(Login))
